import React, { useState, useEffect } from "react";
import { Table, Tooltip, Button } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import { priceFormat, isCSKH } from "../../utils";
import { useSelector } from "react-redux";

export default function PaymentCardDuyetPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter] = useState({});
  const user = useSelector((state) => state.user.userState);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width: 130,
      align: "center",
    },
    {
      title: "TIỀN USER",
      dataIndex: "gold",
      key: "gold",
      width: 150,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "TIỀN NẠP",
      dataIndex: "updateGold",
      key: "updateGold",
      width: 150,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "MÃ THẺ",
      dataIndex: "cardCode",
      key: "cardCode",
      width: 130,
      align: "center",
    },
    {
      title: "SERI",
      dataIndex: "cardSeri",
      key: "cardSeri",
      width: 130,
      align: "center",
    },
    {
      title: "MỆNH GIÁ",
      dataIndex: "menhGia",
      key: "menhGia",
      render: (data) => priceFormat(data),
      width: 100,
      align: "center",
    },
    {
      title: "NHÀ MẠNG",
      dataIndex: "nhaMang",
      key: "nhaMang",
      width: 100,
      align: "center",
    },
    {
      title: "MÃ GIAO DỊCH",
      dataIndex: "orderid",
      key: "orderid",
      width: 180,
      align: "center",
    },
    {
      title: "NGƯỜI GỬI",
      dataIndex: "sender",
      key: "sender",
      width: 100,
      align: "center",
    },
    {
      title: "CHI TIẾT",
      dataIndex: "detail",
      key: "detail",
      width: 300,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      key: "status",
      width: 130,
      align: "center",
      render: (data, record) => {
        switch (data) {
          case 0:
            return <p className="text-green-400	font-semibold">Chờ duyệt</p>;
          case 1:
            return <p className="text-green-400	font-semibold">Thành công</p>;
          case 2:
            return <p className="text-green-400	font-semibold">Không duyệt</p>;
          case 3:
            return <p className="text-green-400	font-semibold">Chờ cổng</p>;
          default:
            return <p className="text-red-600	"></p>;
        }
      },
    },
    {
      title: "DUYỆT",
      dataIndex: "action",
      key: "action",
      width: 300,
      align: "center",
      render: (data, record) => {
        const handleUpdate = (orderid, type) => {
          updateCardDuyet(orderid, type);
        };
        switch (record.status) {
          case 0:
            return (
              <div>
                <Button
                  type="primary"
                  onClick={() => handleUpdate(record.orderid, 1)}
                >
                  Duyệt thẻ
                </Button>
                <span>&ensp;&ensp;</span>
                <Button
                  type="primary"
                  onClick={() => handleUpdate(record.orderid, 0)}
                >
                  Không duyệt
                </Button>
              </div>
            );
            break;
          default:
            return <p className="text-red-600	"></p>;
            break;
        }
      },
    },
    {
      title: "NỀN TẢNG",
      dataIndex: "platform",
      key: "platform",
      width: 110,
      align: "center",
    },
    {
      title: "IP",
      dataIndex: "IP",
      key: "IP",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 200,
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];

  async function getPaymentCardDuyetList() {
    try {
      const data = await APIService.paymentCardDuyetList();
      if (data) {
        // cskh chỉ cho phép xem trong n ngày gần nhất
        if (isCSKH(user)) {
          let to = new Date();
          to.setHours(23);
          to.setMinutes(59);
          to.setSeconds(59);
          let from = new Date();
          from.setDate(
            to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER
          );
          from.setHours(0);
          from.setMinutes(0);
          from.setSeconds(0);
          for (let i = 0; i < data.length; i++) {
            let createdDate = moment(data[i].timestamp);
            let limitDate = moment(from);
            if (createdDate.isBefore(limitDate)) {
              data.splice(i, 1);
              i--;
            }
          }
        }
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateCardDuyet(orderid, type) {
    try {
      const data = await APIService.updateCardDuyet(orderid, type);
      if (data) {
        getPaymentCardDuyetList();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getPaymentCardDuyetList();
  }, []);

  return (
    <>
      <FormSearchFilter
        title="Duyệt thẻ"
        initialValues={filter}
      ></FormSearchFilter>
      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { Form, Table, Select, DatePicker, Typography, Tooltip } from "antd"
import FormSearchFilter from "../../components/FormSearchFilter"
import APIService from "../../service/APIService"
import moment from "moment"
import { priceFormat } from "../../utils"
const { RangePicker } = DatePicker
const dateFormatPicker = "DD-MM-YYYY"
const { Text } = Typography

export default function ThongKeTrackingPage() {
  const [requestType, setRequestType] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
    requestType: "",
  })

  function getActionDescription(action) {
    return (
      "[JOIN,LOGIN_FALSE,LOGIN_SUCCESS,REGISTER_FALSE,REGISTER_SUCCESS]: " +
      action[0] +
      "," +
      action[1] +
      "," +
      action[2] +
      "," +
      action[3] +
      "," +
      action[4]
    )
  }

  const columns = [
    {
      title: "NGÀY",
      dataIndex: "days",
      key: "days",
      align: "center",
    },
    {
      title: "ANDROID",
      dataIndex: "",
      key: "",
      align: "center",
      render: (data) => (
        <Tooltip
          placement="top"
          title={getActionDescription(data[filter.requestType][0].arr)}
        >
          <div>{priceFormat(data[filter.requestType][0].sum)}</div>
        </Tooltip>
      ),
    },
    {
      title: "IOS",
      dataIndex: "",
      key: "",
      align: "center",
      render: (data) => (
        <Tooltip
          placement="top"
          title={getActionDescription(data[filter.requestType][1].arr)}
        >
          <div>{priceFormat(data[filter.requestType][1].sum)}</div>
        </Tooltip>
      ),
    },
    {
      title: "WEB",
      dataIndex: "",
      key: "",
      align: "center",
      render: (data) => (
        <Tooltip
          placement="top"
          title={getActionDescription(data[filter.requestType][3].arr)}
        >
          <div>{priceFormat(data[filter.requestType][3].sum)}</div>
        </Tooltip>
      ),
    },
    {
      title: "APK",
      dataIndex: "",
      key: "",
      align: "center",
      render: (data) => (
        <Tooltip
          placement="top"
          title={getActionDescription(data[filter.requestType][4].arr)}
        >
          <div>{priceFormat(data[filter.requestType][4].sum)}</div>
        </Tooltip>
      ),
    },
  ]

  async function getStatisTrackingList() {
    try {
      const object = await APIService.statisTrackingList(
        filter.timeFrom,
        filter.timeTo,
        filter.requestType
      )
      if (object) {
        setRequestType(object.keys)
        let data = object.data
        data.sort(function compare(a, b) {
          let dateA = moment(a.days, "DD-MM-YYYY").toDate()
          let dateB = moment(b.days, "DD-MM-YYYY").toDate()
          return dateB.getTime() - dateA.getTime()
        })
        setDataTable(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getStatisTrackingList()
  }, [filter])

  return (
    <>
      <FormSearchFilter
        title="Thống kê Tracking"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format("YYYY-MM-DD"),
              timeTo: moment(value.time[1]).format("YYYY-MM-DD"),
            })
          } else {
            setFilter({ ...filter, ...value })
          }
        }}
      >
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
        <Form.Item label="Request Type" name="requestType">
          <Select options={requestType}></Select>
        </Form.Item>
      </FormSearchFilter>

      <Table
        columns={columns}
        dataSource={dataTable}
        summary={(pageData) => {
          let tong = {}
          dataTable.forEach(function (obj) {
            obj[filter.requestType].forEach((item) => {
              if (tong.hasOwnProperty(item.name)) {
                tong[item.name] += item.sum
              } else {
                tong[item.name] = item.sum
              }
            })
          })
          return (
            <>
              <Table.Summary.Row className="bg-slate-100">
                <Table.Summary.Cell index={0} f colSpan={1}>
                  <p className="font-semibold">Tổng cộng</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["AND"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["IOS"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["WEB"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["APK"])}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
    </>
  )
}

import React, {useState, useEffect, useRef} from "react";
import {Form, Table, Input, Button, Modal, Tooltip, Select, Space, Typography, message} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import {priceFormat} from "../../utils";
import ButtonCopyClipboard from "../../components/ButtonCopyClipboard";
const { Title } = Typography;

export default function UserInfoPage() {
  const [dataTable, setDataTable] = useState([]);
  const [dataMoneyLog, setDataMoneyLog] = useState([]);
  const [open, setOpen] = useState(false);
  const [isCodePayProcessing, setIsCodePayProcessing] = useState(false);
  const [openCodePay, setOpenCodePay] = useState(false);
  const [dataCodePay, setDataCodePay] = useState({
    userid: "", bankAccountName: "", bankAccountNumber: "", contentCode: "", payUrl:"", qrpay: ""
  });
  const [filter, setFilter] = useState({
    userid: "", keyword: "",
  });
  const [codePayBankCode, setCodePayBankCode] = useState('MB');
  const [codePayAmount, setCodePayAmount] = useState(50000);
  const userInfoList = useRef();

  function getCodePay(userid) {
    setDataCodePay({
      userid: userid, bankAccountName: "", bankAccountNumber: "", contentCode: "", payUrl: "", qrpay: ""
    })
    setOpenCodePay(true);
  }

  function submitGetCodePay() {
    setIsCodePayProcessing(true)
    APIService.paymentBank(dataCodePay['userid'], codePayBankCode, codePayBankCode, codePayAmount).then(data=>{
      if(!data){
        message.success('Lỗi không thể nạp code').then();
        return
      }
      const {page_url, url_qrpay, account_name, account, content} = data || {page_url: "", url_qrpay:"", account_name:"", account:"", content:""};
      if(account && content && account_name){
        setDataCodePay({
          userid: dataCodePay['userid'], bankAccountName: account_name, bankAccountNumber: account, contentCode: content,
          payUrl: page_url, qrpay: url_qrpay
        })
      }

    }).finally(()=>setIsCodePayProcessing(false))
  }

  const columns = [{
    title: "USER ID", dataIndex: "userid", key: "userid", align: "center", width: 150,
  }, {
    title: "TÊN ĐĂNG NHẬP", dataIndex: "username", key: "username", width: 150
  }, {
    title: "NAME", dataIndex: "name", key: "name", width: 150
  }, /*{
    title: "CODEPAY", dataIndex: "userid", key: "userid", width: 100, render(data, rowData) {
      return rowData['bot'] ? <></> : <Button size={'small'} onClick={() => {
          getCodePay(data)
        }}>Lấy mã</Button>
    }
  },*/ {
    title: "LỊCH SỬ CHƠI",
    dataIndex: "chip_pool",
    key: "chip_pool",
    align: "center",
    width: 120,
    render: (data, data1, index) => (<Button
        className="text-center"
        type="primary"
        onClick={() => {
          getMoneyLogList(data1.userid);
        }}
      >
        Xem
      </Button>),
  }, {
    title: "BOT", dataIndex: "bot", key: "bot", align: "center", render: (data) => <p>{data.toString()}</p>, width: 75
  }, {
    title: "IP", dataIndex: "ip", key: "ip", width: 200, align: "center",
  }, {
    title: "NỀN TẢNG", dataIndex: "platform", key: "platform", align: "center",
  }, {
    title: "IS SUN", dataIndex: "isSun", key: "isSun", align: "center",
  }, {
    title: "ĐĂNG NHẬP LẦN CUỐI",
    dataIndex: "lastedLogin",
    width: 200,
    key: "lastedLogin",
    align: "center",
    render: (data) => (<p>{moment(data * 1000).format("DD-MM-YYYY hh:mm:ss")}</p>),
  }, {
    title: "CHI TIẾT",
    dataIndex: "lastedLogin",
    width: 100,
    key: "",
    align: "center",
    render: (data, data1, index) => (<Button
        className="text-center"
        type="primary"
        onClick={() => {
          window.location = "./user-detail?userid=" + data1.userid;
        }}
      >
        Xem
      </Button>),
  },];
  const columnsMoneyLog = [{
    title: "ID", dataIndex: "id", key: "id", width: 140, sorter: (a, b) => a.id - b.id, align: "center",
  },

    {
      title: "GAME", width: 140, dataIndex: "game", key: "game",
    }, {
      title: "LOG", width: 250, dataIndex: "log", key: "log", ellipsis: {
        showTitle: false,
      }, render: (data) => (<Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>), align: "center",
    }, {
      title: "TIỀN", width: 160, dataIndex: "gold", key: "gold", render: (data) => priceFormat(data),
    }, {
      title: "TIỀN THẮNG/THUA",
      dataIndex: "updateGold",
      key: "updateGold",
      sorter: (a, b) => a.updateGold - b.updateGold,
      render: (data) => priceFormat(data),
      width: 200,
    }, {
      title: "DETAIL", dataIndex: "detail", key: "detail", width: 250, ellipsis: {
        showTitle: false,
      }, render: (data) => (<Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>),
    }, {
      title: "TIME",
      dataIndex: "time",
      key: "time",
      width: 200,
      align: "center",
      sorter: (a, b) => moment(a.time).valueOf() - moment(b.time).valueOf(),
      render: (data, data1, index) => (<p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>),
    },];

  async function getMoneyLogList(player) {
    try {
      const data = await APIService.moneyLogList(player, "", "");
      if (data) {
        setDataMoneyLog(data);
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserInfoList() {
    try {
      const data = await APIService.userInfoList();
      if (data) {
        userInfoList.current = data;
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getSearchUserInfo() {
    try {
      const data = await APIService.searchUserInfo(filter.userid.trim(), filter.keyword.trim());
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getUserInfoList();
  }, []);

  useEffect(() => {
    if (filter.userid.length || filter.keyword.length) {
      getSearchUserInfo();
    } else {
      setDataTable(userInfoList.current);
    }
  }, [filter]);

  const bankList = [{
    "stt": 1, "code": "MB", "name": "MB Quân đội", "close_at": -1, "shortName": "mbbank", "open_at": -1
  }, {
    "stt": 1, "code": "ACB", "name": "Acb Á Châu", "close_at": -1, "shortName": "acb", "open_at": -1
  }, {
    "stt": 1,
    "code": "VCB",
    "name": "Vcb Ngoại Thương VN",
    "close_at": 2230,
    "shortName": "vietcombank",
    "open_at": 300
  }, {
    "stt": 1,
    "code": "VIETINBANK",
    "name": "Vietin Công thương VN",
    "close_at": 2230,
    "shortName": "vietinbank",
    "open_at": 200
  }, {
    "stt": 1, "code": "BIDV", "name": "Bidv Đầu tư và Phát triển VN", "close_at": -1, "shortName": "bidv", "open_at": -1
  }, {
    "stt": 1,
    "code": "STB",
    "name": "Sacombank Sài Gòn Thương Tín",
    "close_at": -1,
    "shortName": "sacombank",
    "open_at": -1
  }]

  const bankAmountList = [{
    "xu": "50,000", "amount": 50000, "id": 0
  }, {
    "xu": "100,000", "amount": 100000, "id": 1
  }, {
    "xu": "200,000", "amount": 200000, "id": 2
  }, {
    "xu": "500,000", "amount": 500000, "id": 3
  }, {
    "xu": "1000,000", "amount": 1000000, "id": 4
  }, {
    "xu": "2,000,000", "amount": 2000000, "id": 5
  }, {
    "xu": "5,000,000", "amount": 5000000, "id": 6
  }, {
    "xu": "10,000,000", "amount": 10000000, "id": 7
  },{
    "xu": "0", "amount": 0, "id": 0
  }]

  return (<>
      <FormSearchFilter
        title="Thông tin"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          setFilter({...filter, ...value});
        }}
      >
        <Form.Item label="Player ID" className="form-item-filter" name="userid">
          <Input className="w-full"/>
        </Form.Item>
        <Form.Item
          label="Username / Name"
          className="form-item-filter"
          name="keyword"
        >
          <Input className="w-full"/>
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} rowKey={'userid'}/>

      <Modal
        title={dataMoneyLog[0]?.player}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1100}
        footer={false}
      >
        <Table columns={columnsMoneyLog} dataSource={dataMoneyLog} rowKey={'id'}/>
      </Modal>
      <Modal
        title={`Nạp CodePay USER: ${dataCodePay['userid']}`}
        centered
        open={openCodePay}
        onOk={() => setOpenCodePay(false)}
        onCancel={() => setOpenCodePay(false)}
        width={600}
        footer={false}
      >
        <div style={{marginBottom: '15px'}}>
          <Space>
            <Title level={5}>Bank Code</Title>
            <Select
              defaultValue="MB"
              onChange={(value)=>setCodePayBankCode(value)}
              options={bankList.map(i=>({value: i['code'], label: i['name']}))}
            />

            <Title level={5}>Amount</Title>
            <Select
              defaultValue={50000}
              onChange={(value)=>setCodePayAmount(value)}
              options={bankAmountList.map(i=>({value: i['amount'], label: i['xu']}))}
            />

            <Button loading={isCodePayProcessing} onClick={submitGetCodePay}>Lấy code</Button>
          </Space>
        </div>
        <hr/><br/>
        <p><span>Tên tài khoản: </span> <strong>{dataCodePay['bankAccountName']}</strong> {dataCodePay['bankAccountName'] ? <ButtonCopyClipboard content={dataCodePay['bankAccountName']}/> : <></>}</p>
        <p><span>Số tài khoản: </span> <strong>{dataCodePay['bankAccountNumber']}</strong> {dataCodePay['bankAccountNumber'] ? <ButtonCopyClipboard content={dataCodePay['bankAccountNumber']}/> : <></>}</p>
        <p><span>Code: </span> <strong>{dataCodePay['contentCode']}</strong> {dataCodePay['contentCode'] ? <ButtonCopyClipboard content={dataCodePay['contentCode']}/> : <></>}</p>
        <p><span>pay url: </span> <strong>{dataCodePay['payUrl']}</strong> {dataCodePay['payUrl'] ? <ButtonCopyClipboard content={dataCodePay['payUrl']}/> : <></>}</p>
        {dataCodePay['qrpay'] ? <p style={{display: 'flex', alignItems: 'center'}}><img width={150} src={dataCodePay['qrpay']} alt="qrCode"/> <ButtonCopyClipboard content={dataCodePay['qrpay']}/></p> : <></>}
      </Modal>
  </>);
}

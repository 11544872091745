import { message, notification } from "antd";
import axios from "axios";
import { store } from "../redux/store";
import { setUser, selectUser } from "../redux/features/userSlice";

const DOMAIN = process.env.REACT_APP_API_URL;

const fetchAuth = () => {
  const state = store.getState();
  const user = selectUser(state);
  return user?.token;
};

export const get = async (func, params = {}) => {
  let url = DOMAIN + func;
  const headers = {
    'content-type': 'application/x-www-form-urlencoded',
    accesstoken: fetchAuth(),
  };
  const response = await axios.get(url, { headers, params });
  return response.data;
};

export const post = async (
  func,
  params = {},
  body = {},
  headers = {
    "Content-Type": "application/json",
  }
) => {
  // message.loading({ content: "Loading...", key: "load", duration: 0 });
  headers.accesstoken = fetchAuth();

  let url = DOMAIN + func;
  const response = await axios.post(url, body, {
    headers: headers,
    params,
  });
  return response.data;
};

export const put = async (func, params = {}) => {
  // message.loading({ content: "Loading...", key: "load", duration: 0 });
  let url = DOMAIN + func;
  const response = await axios.put(url, params);
  return response.data;
};

export const execute = async (response, isShowProgress) => {
  try {
    if (response.status === 2) {
      if (response.data) {
        if (isShowProgress) {
          notification["success"]({
            message: "Success",
            description: response.msg,
          });
        }
        return response.data;
      }
      return response;
    } else if (response.code === -1) {
      store.dispatch(setUser(null));
    }
    notification["error"]({
      message: "Error! An error occurred. Please try again later",
      description: response.msg || response,
    });
    throw response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

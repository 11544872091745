import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Input,
  DatePicker,
  Tooltip,
  Button,
  notification,
} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, strUcfirst } from "../../utils";
import moment from "moment";
import { SaveOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";

export default function ConfigJackpotLivePage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "money",
      dataIndex: "jackPot",
      key: "jackPot",
      align: "center",
      render: (data,data1) => (
        <Input
          value={priceFormat(data)}
          onChange={(text) => {
            setDataTable(
              dataTable.map((item, _index) => {
                if (item.id == data1.id) {
                  return {
                    ...item,
                    jackPot: text.target.value,
                  };
                } else {
                  return item;
                }
              })
            );
          }}
        ></Input>
      ),
    },
    {
      title: "pool",
      dataIndex: "pool",
      key: "pool",
      align: "center",
      render: (data, data1, index) => (
        <Input
          value={priceFormat(data)}
          onChange={(text) => {
            setDataTable(
              dataTable.map((item, _index) => {
                if (item.id == data1.id) {
                  return {
                    ...item,
                    pool: text.target.value,
                  };
                } else {
                  return item;
                }
              })
            );
          }}
        />
      ),
    },

    {
      title: "timeStamp",
      dataIndex: "timeStamp",
      key: "timeStamp",
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm")}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
      render: (data, data1, index) => (
        <Button
          className="text-center"
          type="primary"
          onClick={() => {
            getUpdateJackpot(
              data1.id,
              data1.jackPot,
              data1.pool,
            );
          }}
          icon={<SaveOutlined />}
        >
          Save
        </Button>
      ),
    },
  ];

  async function getUpdateJackpot(id, money, pool) {
    try {
      const data = await APIService.updateJackpot(id, money, pool);
      if (data) {
        console.log(data);
        notification["success"]({
          message: "Thành công",
          description: `${id} đã được cập nhật.`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getJackpotLiveList() {
    try {
      const data = await APIService.jackpotLiveList();
      if (data) {
        console.log(data);
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getJackpotLiveList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter title="Config Jackpot Live"></FormSearchFilter>
      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, strUcfirst } from "../../utils";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function JackbotLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      width: 180,
      key: "player",
      align: "center",
    },
    {
      title: "TÊN HIỂN THỊ",
      dataIndex: "display_name",
      width: 180,
      key: "display_name",
      align: "center",
    },
    {
      title: "GAME",
      dataIndex: "game",
      key: "game",
      width: 160,
      align: "center",
    },
    {
      title: "LOẠI",
      dataIndex: "type",
      key: "type",
      width: 100,
      align: "center",
    },
    {
      title: "CURRENT MONEY",
      dataIndex: "current_money",
      key: "current_money",
      width: 160,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "CUREENT POOL",
      dataIndex: "current_pool",
      key: "current_pool",
      width: 160,
      align: "center",
    },
    {
      title: "UPDATE MONEY",
      dataIndex: "update_money",
      key: "update_money",
      width: 160,
      sorter: (a, b) => a.update_money - b.update_money,
      render: (data) => priceFormat(data),
      align: "center",
   
    },
    {
      title: "TIMESTAMP",
      dataIndex: "timestamp",
      key: "timestamp",
      align: "center",
      width: 160,
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
  ];

  async function getJackpotLogList() {
    try {
      const data = await APIService.jackpotLogList(
        filter.player,
        filter.timeFrom,
        filter.timeTo
      );
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getJackpotLogList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
      title="Jackpot Log"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          console.log(value);
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="User ID" className="form-item-filter" name="player">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

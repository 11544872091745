import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip, Button, notification, InputNumber } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, strUcfirst } from "../../utils";
import moment from "moment";
import {
  SaveOutlined
} from "@ant-design/icons";
const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function ConfigJackpotPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 200,
    },
    {
      title: "roomId",
      dataIndex: "roomId",
      key: "roomId",
      width: 140,
      align: "center",
    },
    {
      title: "Appear",
      dataIndex: "timeAppear",
      key: "timeAppear",
      width: 140,
      align: "center",
      render: (data, data1) => <InputNumber value={data} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                timeAppear: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
    },
    {
      title: "Swim",
      dataIndex: "timeSwim",
      key: "timeSwim",
      width: 140,
      align: "center",
      render: (data, data1) => <InputNumber value={data} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                timeSwim: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
    },
    {
      title: "moneyDefault",
      dataIndex: "moneyDefault",
      key: "moneyDefault",
      width: 140,
      render: (data, data1) => <InputNumber value={priceFormat(data)} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                moneyDefault: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
      align: "center",
    },
    {
      title: "moneyMaxPool",
      dataIndex: "moneyMaxPool",
      key: "moneyMaxPool",
      width: 160,
      render: (data, data1) => <InputNumber value={priceFormat(data)} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                moneyMaxPool: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
      align: "center",
    },
    {
      title: "WinNormal",
      dataIndex: "perMoneyWinNormal",
      key: "perMoneyWinNormal",
      width: 140,
      align: "center",
      render: (data, data1) => <InputNumber value={data} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                perMoneyWinNormal: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
    },
    {
      title: "AddToJackpot",
      dataIndex: "perMoneyAddToJackpot",
      key: "perMoneyAddToJackpot",
      width: 140,
      align: "center",
      render: (data, data1) => <InputNumber value={data} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                perMoneyAddToJackpot: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
    },
    {
      title: "AddToPool",
      dataIndex: "perMoneyAddToPool",
      key: "perMoneyAddToPool",
      width: 140,
      align: "center",
      render: (data, data1) => <InputNumber value={data} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                perMoneyAddToPool: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
    },
    {
      title: "PerDay",
      dataIndex: "perWin2PerDay",
      key: "perWin2PerDay",
      width: 140,
      align: "center",
      render: (data, data1) => <InputNumber value={data} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                perWin2PerDay: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
    },
    {
      title: "PerSecond",
      dataIndex: "numMoneyPerSecond",
      key: "numMoneyPerSecond",
      width: 140,
      align: "center",
      render: (data, data1) => <InputNumber value={data} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                numMoneyPerSecond: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
    },
    {
      title: "minMoney",
      dataIndex: "minMoneyCanHit",
      key: "minMoneyCanHit",
      width: 140,
      align: "center",
      render: (data, data1) => <InputNumber value={data} onChange={(value) => {
        setDataTable(
          dataTable.map((item, _index) => {
            if (item.id == data1.id) {
              return {
                ...item,
                minMoneyCanHit: value,
              };
            } else {
              return item;
            }
          })
        );
      }}></InputNumber> ,
    },
    {
      title: "timeStamp",
      dataIndex: "timeStamp",
      key: "timeStamp",
      align: "center",
      width: 180,
      render: (data, data1, index) => (
        <p>{moment(data).format('DD-MM-YYYY HH:mm')}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 140,
      render: (data, data1, index) => (
        <Button className="text-center" type="primary"  icon={<SaveOutlined  />} onClick={() => {
          updateConfigJackpot(data1);
        }} >Save</Button>
      ),
    },
    
  ];

  async function getConfigJackpotList() {
    try {
      const data = await APIService.configJackpotList(
      );
      if (data) {
        console.log(data);
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateConfigJackpot(data_update) {
    try {
      const data = await APIService.updateConfigJackpot(data_update.id,
        data_update.minMoneyCanHit,
        data_update.perMoneyAddToJackpot,
        data_update.numMoneyPerSecond,
        data_update.perMoneyAddToPool,
        data_update.moneyDefault,
        data_update.moneyMaxPool,
        data_update.perMoneyWinNormal,
        data_update.timeAppear,
        data_update.timeSwim,
        data_update.perWin2PerDay);
      if (data) {
        console.log(data);
        notification['success']({
          message: 'Thành công',
          description:
            `${data_update.id} đã được cập nhật.`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    getConfigJackpotList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
      title="Config Jackpot"

      >
       
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

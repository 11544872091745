import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip, Button, notification } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { strUcfirst } from "../../utils";
import moment from "moment";
import {
  SaveOutlined
} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function ConfigGameStockPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width:"8%",
      align: "center",
    },
    {
      title: "roomId",
      dataIndex: "roomId",
      key: "roomId",
      align: "center",
    },
    {
      title: "gameId",
      dataIndex: "gameId",
      key: "gameId",
      align: "center",
    },
    {
      title: "typeStock",
      dataIndex: "typeStock",
      key: "typeStock",
      align: "center",
    },
    {
        title: "currentStock",
        dataIndex: "values",
        key: "values",
        align: "center",
        editing: true,
        render: (data, data1) => <Input value={data[0].currentStock} onChange={(text) => {
          setDataTable(
            dataTable.map((item, _index) => {
              if (item.id == data1.id) {
                return {
                  ...item,
                  values: [{...item.values[0], currentStock: text.target.value,}],
                };
              } else {
                return item;
              }
            })
          );
        }}></Input> ,

      },
    {
      title: "timeStamp",
      dataIndex: "timeStamp",
      key: "timeStamp",
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data).format('DD-MM-YYYY HH:mm')}</p>
      ),
    },
      {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
      render: (data, data1, index) => (
        <Button className="text-center" type="primary"  icon={<SaveOutlined  />}  onClick={() => {
          updateGameStock(data1.id, data1.values[0].currentStock);
        }}  >Save</Button>
      ),
    },
    
  ];

  async function getGameStockList() {
    try {
      const data = await APIService.gameStockList();
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateGameStock(id, currentStock) {
    try {
      const data = await APIService.updateGameStock(id, currentStock);
      if (data) {
        notification["success"]({
          message: "Thành công",
          description: `${id} đã được cập nhật.`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getGameStockList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
      title="Config Stock Ca"
       
        
      >
       
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Input, Button, notification } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";

export default function ConfigGamePage() {
  const [data, setData] = useState({});

  async function getConfigPaymentInfo() {
    try {
      const data = await APIService.configPaymentInfo();
      if (data) {
        console.log(data);
        setData(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateConfigPaymentInfo(type, config) {
    try {
      const data = await APIService.updateConfigPaymentInfo(type, config);
      if (data) {
        console.log(data);
        notification["success"]({
          message: "Thành công",
          description: `${type} đã được cập nhật.`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getConfigPaymentInfo();
  }, []);

  return (
    <>
      <FormSearchFilter title="Game Info"></FormSearchFilter>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ARR_GAME</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ARR_GAME?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ARR_GAME: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("ARR_GAME", data.ARR_GAME);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ARR_WD</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ARR_WD?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ARR_WD: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("ARR_WD", data.ARR_WD);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ARR_DEPOSIT</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ARR_DEPOSIT?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ARR_DEPOSIT: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("ARR_DEPOSIT", data.ARR_DEPOSIT);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">FANPAGE</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.FANPAGE?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                FANPAGE: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("FANPAGE", data.FANPAGE);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">IS_MAINTAIN</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.IS_MAINTAIN?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                IS_MAINTAIN: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("IS_MAINTAIN", data.IS_MAINTAIN);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">JACKPOT_USER_MONEY</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.JACKPOT_USER_MONEY?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                JACKPOT_USER_MONEY: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "JACKPOT_USER_MONEY",
                data.JACKPOT_USER_MONEY
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">JACKPOT_MONEY</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.JACKPOT_MONEY?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                JACKPOT_MONEY: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("JACKPOT_MONEY", data.JACKPOT_MONEY);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">JACKPOT_CHEAT_PLAYER</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.JACKPOT_CHEAT_PLAYER?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                JACKPOT_CHEAT_PLAYER: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "JACKPOT_CHEAT_PLAYER",
                data.JACKPOT_CHEAT_PLAYER
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div className="h-10 bg-[#eef0f3]" />
      <FormSearchFilter title="Bank Info"></FormSearchFilter>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">TAIXIU_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_GAME_BANK",
                data.TAIXIU_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">SICBO_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.SICBO_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                SICBO_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("SICBO_GAME_BANK", data.SICBO_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">XOCDIA_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.XOCDIA_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                XOCDIA_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "XOCDIA_GAME_BANK",
                data.XOCDIA_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">BAUCUA_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.BAUCUA_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                BAUCUA_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "BAUCUA_GAME_BANK",
                data.BAUCUA_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">WUKONG_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.WUKONG_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                WUKONG_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "WUKONG_GAME_BANK",
                data.WUKONG_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">META_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.META_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                META_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("META_GAME_BANK", data.META_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">GODOFWAR_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.GODOFWAR_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                GODOFWAR_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "GODOFWAR_GAME_BANK",
                data.GODOFWAR_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">FISH_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.FISH_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                FISH_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("FISH_GAME_BANK", data.FISH_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">LADY_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.LADY_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                LADY_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("LADY_GAME_BANK", data.LADY_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">STTT_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.STTT_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                STTT_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("STTT_GAME_BANK", data.STTT_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">NATRA_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.NATRA_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                NATRA_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("NATRA_GAME_BANK", data.NATRA_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ICE_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ICE_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ICE_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("ICE_GAME_BANK", data.ICE_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THUYCUNG_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THUYCUNG_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THUYCUNG_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THUYCUNG_GAME_BANK",
                data.THUYCUNG_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">AVENGER_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.AVENGER_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                AVENGER_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "AVENGER_GAME_BANK",
                data.AVENGER_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ROULETTE_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ROULETTE_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ROULETTE_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "ROULETTE_GAME_BANK",
                data.ROULETTE_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">KYLAN_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.KYLAN_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                KYLAN_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("KYLAN_GAME_BANK", data.KYLAN_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THANTAI_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THANTAI_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THANTAI_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THANTAI_GAME_BANK",
                data.THANTAI_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">MAYBAY_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.MAYBAY_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                MAYBAY_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "MAYBAY_GAME_BANK",
                data.MAYBAY_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PIRATEKING_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PIRATEKING_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PIRATEKING_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PIRATEKING_GAME_BANK",
                data.PIRATEKING_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">BATUOC_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.BATUOC_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                BATUOC_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "BATUOC_GAME_BANK",
                data.BATUOC_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">LUCKKYLUCK_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.LUCKKYLUCK_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                LUCKKYLUCK_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "LUCKKYLUCK_GAME_BANK",
                data.LUCKKYLUCK_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">TAYDUKY_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAYDUKY_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAYDUKY_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAYDUKY_GAME_BANK",
                data.TAYDUKY_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            KHOTANGNGULONG_GAME_BANK
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.KHOTANGNGULONG_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                KHOTANGNGULONG_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "KHOTANGNGULONG_GAME_BANK",
                data.KHOTANGNGULONG_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ONGDO_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ONGDO_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ONGDO_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("ONGDO_GAME_BANK", data.ONGDO_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            PHACODENRONG_GAME_BANK
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PHACODENRONG_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PHACODENRONG_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PHACODENRONG_GAME_BANK",
                data.PHACODENRONG_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">LODESIEUTOC_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.LODESIEUTOC_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                LODESIEUTOC_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "LODESIEUTOC_GAME_BANK",
                data.LODESIEUTOC_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">WORLDDANCE_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.WORLDDANCE_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                WORLDDANCE_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "WORLDDANCE_GAME_BANK",
                data.WORLDDANCE_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">TULINH_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TULINH_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TULINH_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TULINH_GAME_BANK",
                data.TULINH_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">HALLOWEEN_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.HALLOWEEN_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                HALLOWEEN_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "HALLOWEEN_GAME_BANK",
                data.HALLOWEEN_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            CUNGHYPHATTAI_GAME_BANK
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.CUNGHYPHATTAI_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                CUNGHYPHATTAI_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "CUNGHYPHATTAI_GAME_BANK",
                data.CUNGHYPHATTAI_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">POKERMEGA_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.POKERMEGA_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                POKERMEGA_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "POKERMEGA_GAME_BANK",
                data.POKERMEGA_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THEWICHER_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THEWICHER_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THEWICHER_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THEWICHER_GAME_BANK",
                data.THEWICHER_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">CAYKHE_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.CAYKHE_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                CAYKHE_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "CAYKHE_GAME_BANK",
                data.CAYKHE_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="h-10 bg-[#eef0f3]" />
      <FormSearchFilter title="Bank Config"></FormSearchFilter>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">TAIXIU_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_BANK_CONFIG",
                data.TAIXIU_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">
            TAIXIU_MINI_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_MINI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_MINI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_MINI_BANK_CONFIG",
                data.TAIXIU_MINI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">SICBO_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.SICBO_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                SICBO_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "SICBO_BANK_CONFIG",
                data.SICBO_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">XOCDIA_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.XOCDIA_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                XOCDIA_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "XOCDIA_BANK_CONFIG",
                data.XOCDIA_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">BAUCUA_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.BAUCUA_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                BAUCUA_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "BAUCUA_BANK_CONFIG",
                data.BAUCUA_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">
            TAIXIUHIT_MINI_LIVE_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIUHIT_MINI_LIVE_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIUHIT_MINI_LIVE_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIUHIT_MINI_LIVE_BANK_CONFIG",
                data.TAIXIUHIT_MINI_LIVE_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">
            TAIXIU_MINI_LIVE_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_MINI_LIVE_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_MINI_LIVE_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_MINI_LIVE_BANK_CONFIG",
                data.TAIXIU_MINI_LIVE_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div className="col-span-2">
          <p className="text-base font-medium text-sm">
            TAIXIU_MINI_JP_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_MINI_JP_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_MINI_JP_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_MINI_JP_BANK_CONFIG",
                data.TAIXIU_MINI_JP_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">GODOFWAR_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.GODOFWAR_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                GODOFWAR_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "GODOFWAR_BANK_CONFIG",
                data.GODOFWAR_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">WUKONG_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.WUKONG_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                WUKONG_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "WUKONG_BANK_CONFIG",
                data.WUKONG_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">META_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.META_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                META_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "META_BANK_CONFIG",
                data.META_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">FISH_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.FISH_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                FISH_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "FISH_BANK_CONFIG",
                data.FISH_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">LADY_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.LADY_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                LADY_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "LADY_BANK_CONFIG",
                data.LADY_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">STTT_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.STTT_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                STTT_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "STTT_BANK_CONFIG",
                data.STTT_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">KYLAN_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.KYLAN_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                KYLAN_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "KYLAN_BANK_CONFIG",
                data.KYLAN_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">SLOT_MINI_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.SLOT_MINI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                SLOT_MINI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "SLOT_MINI_BANK_CONFIG",
                data.SLOT_MINI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THANTAI_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THANTAI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THANTAI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THANTAI_BANK_CONFIG",
                data.THANTAI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THUYCUNG_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THUYCUNG_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THUYCUNG_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THUYCUNG_BANK_CONFIG",
                data.THUYCUNG_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">UPDOWN_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.UPDOWN_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                UPDOWN_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "UPDOWN_BANK_CONFIG",
                data.UPDOWN_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            UPDOWN_MINI_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.UPDOWN_MINI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                UPDOWN_MINI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "UPDOWN_MINI_BANK_CONFIG",
                data.UPDOWN_MINI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            PIRATEKING_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PIRATEKING_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PIRATEKING_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PIRATEKING_BANK_CONFIG",
                data.PIRATEKING_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">NATRA_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.NATRA_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                NATRA_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "NATRA_BANK_CONFIG",
                data.NATRA_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            POKER_MINI_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.POKER_MINI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                POKER_MINI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "POKER_MINI_BANK_CONFIG",
                data.POKER_MINI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">BATUOC_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.BATUOC_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                BATUOC_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "BATUOC_BANK_CONFIG",
                data.BATUOC_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">AVENGER_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.AVENGER_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                AVENGER_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "AVENGER_BANK_CONFIG",
                data.AVENGER_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">MAYBAY_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.MAYBAY_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                MAYBAY_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "MAYBAY_BANK_CONFIG",
                data.MAYBAY_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ICE_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ICE_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ICE_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("ICE_BANK_CONFIG", data.ICE_BANK_CONFIG);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THEWICHER_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THEWICHER_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THEWICHER_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THEWICHER_BANK_CONFIG",
                data.THEWICHER_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ONGDO_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ONGDO_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ONGDO_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "ONGDO_BANK_CONFIG",
                data.ONGDO_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            WORLDDANCE_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.WORLDDANCE_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                WORLDDANCE_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "WORLDDANCE_BANK_CONFIG",
                data.WORLDDANCE_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            CUNGHYPHATTAI_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.CUNGHYPHATTAI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                CUNGHYPHATTAI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "CUNGHYPHATTAI_BANK_CONFIG",
                data.CUNGHYPHATTAI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            LODESIEUTOC_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.LODESIEUTOC_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                LODESIEUTOC_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "LODESIEUTOC_BANK_CONFIG",
                data.LODESIEUTOC_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            LUCKKYLUCK_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.LUCKKYLUCK_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                LUCKKYLUCK_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "LUCKKYLUCK_BANK_CONFIG",
                data.LUCKKYLUCK_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">TULINH_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TULINH_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TULINH_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TULINH_BANK_CONFIG",
                data.TULINH_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            TAIXIU_MINI_MD5_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_MINI_MD5_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_MINI_MD5_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_MINI_MD5_BANK_CONFIG",
                data.TAIXIU_MINI_MD5_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">CAYKHE_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.CAYKHE_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                CAYKHE_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "CAYKHE_BANK_CONFIG",
                data.CAYKHE_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            PHACODENRONG_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PHACODENRONG_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PHACODENRONG_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PHACODENRONG_BANK_CONFIG",
                data.PHACODENRONG_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            KHOTANGNGULONG_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.KHOTANGNGULONG_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                KHOTANGNGULONG_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "KHOTANGNGULONG_BANK_CONFIG",
                data.KHOTANGNGULONG_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">TAYDUKY_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAYDUKY_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAYDUKY_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAYDUKY_BANK_CONFIG",
                data.TAYDUKY_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">POKERMEGA_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.POKERMEGA_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                POKERMEGA_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "POKERMEGA_BANK_CONFIG",
                data.POKERMEGA_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">HALLOWEEN_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.HALLOWEEN_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                HALLOWEEN_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "HALLOWEEN_BANK_CONFIG",
                data.HALLOWEEN_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </>
  );
}

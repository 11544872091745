import React, {useState, useEffect} from "react";
import {Form, Table, Input, DatePicker, Tooltip} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import {priceFormat} from "../../utils";
import moment from "moment";

const {RangePicker} = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function GameBetLogPage() {
    const [dataTable, setDataTable] = useState([]);
    const [filter, setFilter] = useState({
        player: "",
        timeFrom: "",
        timeTo: "",
    });

    const columns = [
        {
            title: "ID",
            width: 120,
            dataIndex: "id",
            key: "id",
            align: "center",
        },
        {
            title: "USER",
            width: 140,
            dataIndex: "player",
            key: "owner",
            align: "center",
        },
        {
            title: "PHIÊN",
            width: 180,
            dataIndex: "roundId",
            key: "roundId",
            align: "center",
        },
        {
            title: "TIỀN",
            width: 120,
            dataIndex: "gold",
            key: "gold",
            align: "right",
            render: data => {
                return <span>{priceFormat(data)}</span>
            }
        },
        {
            title: "TIỀN CẬP NHẬT",
            width: 220,
            dataIndex: "updateGold",
            key: "updateGold",
            align: "right",
            render: data => {
                return <span>{priceFormat(data)}</span>
            }
        },
        {
            title: "GAME",
            dataIndex: "game",
            key: "game",
            width: 180,
            align: "center",
        },
        {
            title: "NỘI DUNG",
            width: 250,
            dataIndex: "detail",
            key: "detail",
            ellipsis: {
                showTitle: false,
            },
            render: data => (
                <Tooltip placement="topLeft" title={data}>
                    {data}
                </Tooltip>
            ),
            align: "left",
        },
        {
            title: "KẾT QUẢ",
            width: 250,
            dataIndex: "result",
            key: "result",
            ellipsis: {
                showTitle: false,
            },
            render: data => (
                <Tooltip placement="topLeft" title={data}>
                    {data}
                </Tooltip>
            ),
            align: "left",
        },
        {
            title: "LÚC",
            width: 200,
            dataIndex: "timeEnd",
            key: "timeEnd",
            align: "center",
            render: (data, data1, index) => (
                <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
            ),
        },
    ];

    async function getGameBetLogList() {
        try {
            const data = await APIService.gameBetLogList(
                filter.player.trim(),
                filter.timeFrom,
                filter.timeTo
            );
            if (data) {
                setDataTable(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getGameBetLogList().then();
    }, [filter]);

    return (
        <>
            <FormSearchFilter
                title="Game Log"
                initialValues={filter}
                onFormLayoutChange={(value) => {
                    if (value.time) {
                        setFilter({
                            ...filter,
                            timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
                            timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
                        });
                    } else {
                        setFilter({...filter, ...value});
                    }
                }}
            >
                <Form.Item label="User ID" className="form-item-filter" name="player">
                    <Input className="w-full"/>
                </Form.Item>
                <Form.Item label="Time" className="form-datepicker-filter" name="time">
                    <RangePicker format={dateFormatPicker}/>
                </Form.Item>
            </FormSearchFilter>

            <Table columns={columns} dataSource={dataTable}/>
        </>
    );
}

import React, { useState, useEffect } from "react";
import {Form, Table, Input, DatePicker, Button, Modal} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, strUcfirst } from "../../utils";
import moment from "moment";
import {
  SearchOutlined
} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function UserLoginLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width:140,
      align: 'center',
     
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width:200,
      align: 'center',
    
    },
    {
      title: "TÊN ĐĂNG NHẬP",
      dataIndex: "username",
      key: "username",
      width:200,
      align: 'center',
 
     
    },
    {
      title: "TIỀN KHI ĐĂNG NHẬP",
      dataIndex: "goldLogin",
      key: "goldLogin",
      width:240,
      render: (data) => priceFormat(data),
      align: 'center',

    },
    {
      title: "TIỀN KHI ĐĂNG XUẤT",
      dataIndex: "goldLogout",
      key: "goldLogout",
      render: (data) => priceFormat(data),
      align: 'center',
      width:240,
      
    },
    {
      title: "ĐĂNG NHẬP LÚC",
      dataIndex: "loginTime",
      key: "loginTime",
      width:200,
      align: 'center',
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
    {
      title: "ĐĂNG XUẤT LÚC",
      dataIndex: "logoutTime",
      key: "logoutTime",
      align: 'center',
      width:200,
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
    {
      title: "NỀN TẢNG",
      dataIndex: "platform",
      key: "platform",
      width:140,
      align: 'center',

    },
    {
      title: "IP",
      dataIndex: "IP",
      key: "IP",
      width:240,
      align: 'center',
      render: (data, data1, index) => (
          <div>{data} <Button size={'small'} title={'Kiểm tra'} icon={<SearchOutlined/>} onClick={() => {
            getSessionLogListByIP(data).then();
          }}/></div>
      ),
    },
    {
      title: "KÊNH",
      dataIndex: "channel",
      key: "channel",
      width:140,
      align: 'center',
    },
    
  ];

  const columnsModal = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width:140,
      align: 'center',

    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width:200,
      align: 'center',

    },
    {
      title: "TÊN ĐĂNG NHẬP",
      dataIndex: "username",
      key: "username",
      width:200,
      align: 'center',


    },
    {
      title: "TIỀN KHI ĐĂNG NHẬP",
      dataIndex: "goldLogin",
      key: "goldLogin",
      width:240,
      render: (data) => priceFormat(data),
      align: 'center',

    },
    {
      title: "TIỀN KHI ĐĂNG XUẤT",
      dataIndex: "goldLogout",
      key: "goldLogout",
      render: (data) => priceFormat(data),
      align: 'center',
      width:240,

    },
    {
      title: "ĐĂNG NHẬP LÚC",
      dataIndex: "loginTime",
      key: "loginTime",
      width:200,
      align: 'center',
      render: (data, data1, index) => (
          <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
    {
      title: "ĐĂNG XUẤT LÚC",
      dataIndex: "logoutTime",
      key: "logoutTime",
      align: 'center',
      width:200,
      render: (data, data1, index) => (
          <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
    {
      title: "NỀN TẢNG",
      dataIndex: "platform",
      key: "platform",
      width:140,
      align: 'center',

    },
    {
      title: "IP",
      dataIndex: "IP",
      key: "IP",
      width:240,
      align: 'center',
    },
    {
      title: "KÊNH",
      dataIndex: "channel",
      key: "channel",
      width:140,
      align: 'center',
    }
  ];

  async function getSessionLogList() {
    try {
      const data = await APIService.sessionLogList(
        filter.player,
        filter.timeFrom,
        filter.timeTo
      );
      if (data) {
        console.log(data);
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getSessionLogListByIP(ip) {
    try {
      const data = await APIService.sessionLogListByIp(ip);
      if (data) {
        const dataFiltered = [];

        for(let user of data){
          if(dataFiltered.find(i=>i.username === user.username)){
            continue
          }

          dataFiltered.push(user)
        }

        setDataModal(dataFiltered);
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getSessionLogList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
      title="Đăng nhập Log"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="User ID" className="form-item-filter" name="player">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />

      <Modal
          title={"IP: " + dataModal[0]?.IP}
          centered
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={1100}
          footer={false}
      >
        <Table columns={columnsModal} dataSource={dataModal} />
      </Modal>
    </>
  );
}

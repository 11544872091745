import React, { useState, useEffect } from "react";
import { Input, Button, notification } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";

export default function ConfigBankPage() {
  const [data, setData] = useState({});

  async function getConfigPaymentInfo() {
    try {
      const data = await APIService.configPaymentInfo();
      if (data) {
        console.log(data);
        setData(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateConfigPaymentInfo(type, config) {
    try {
      const data = await APIService.updateConfigPaymentInfo(type, config);
      if (data) {
        console.log(data);
        notification["success"]({
          message: "Thành công",
          description: `${type} đã được cập nhật.`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getConfigPaymentInfo();
  }, []);

  return (
    <>
      <FormSearchFilter title="Bank Info"></FormSearchFilter>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">WUKONG_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.WUKONG_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                WUKONG_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "WUKONG_GAME_BANK",
                data.WUKONG_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">META_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.META_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                META_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("META_GAME_BANK", data.META_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">XOCDIA_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.XOCDIA_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                XOCDIA_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "XOCDIA_GAME_BANK",
                data.XOCDIA_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">FISH_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.FISH_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                FISH_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("FISH_GAME_BANK", data.FISH_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">LADY_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.LADY_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                LADY_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("LADY_GAME_BANK", data.LADY_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">STTT_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.STTT_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                STTT_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("STTT_GAME_BANK", data.STTT_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">BAUCUA_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.BAUCUA_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                BAUCUA_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "BAUCUA_GAME_BANK",
                data.BAUCUA_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">TAIXIU_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_GAME_BANK",
                data.TAIXIU_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">NATRA_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.NATRA_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                NATRA_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("NATRA_GAME_BANK", data.NATRA_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ICE_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ICE_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ICE_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("ICE_GAME_BANK", data.ICE_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THUYCUNG_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THUYCUNG_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THUYCUNG_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THUYCUNG_GAME_BANK",
                data.THUYCUNG_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">AVENGER_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.AVENGER_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                AVENGER_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "AVENGER_GAME_BANK",
                data.AVENGER_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ROULETTE_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ROULETTE_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ROULETTE_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "ROULETTE_GAME_BANK",
                data.ROULETTE_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">KYLAN_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.KYLAN_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                KYLAN_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("KYLAN_GAME_BANK", data.KYLAN_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THANTAI_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THANTAI_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THANTAI_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THANTAI_GAME_BANK",
                data.THANTAI_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">MAYBAY_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.MAYBAY_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                MAYBAY_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "MAYBAY_GAME_BANK",
                data.MAYBAY_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PIRATEKING_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PIRATEKING_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PIRATEKING_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PIRATEKING_GAME_BANK",
                data.PIRATEKING_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">SICBO_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.SICBO_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                SICBO_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("SICBO_GAME_BANK", data.SICBO_GAME_BANK);
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">BATUOC_GAME_BANK</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.BATUOC_GAME_BANK?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                BATUOC_GAME_BANK: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "BATUOC_GAME_BANK",
                data.BATUOC_GAME_BANK
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="h-10 bg-[#eef0f3]" />
      <FormSearchFilter title="Bank Config"></FormSearchFilter>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">WUKONG_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.WUKONG_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                WUKONG_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "WUKONG_BANK_CONFIG",
                data.WUKONG_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">META_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.META_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                META_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "META_BANK_CONFIG",
                data.META_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">XOCDIA_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.XOCDIA_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                XOCDIA_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "XOCDIA_BANK_CONFIG",
                data.XOCDIA_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">FISH_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.FISH_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                FISH_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "FISH_BANK_CONFIG",
                data.FISH_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">LADY_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.LADY_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                LADY_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "LADY_BANK_CONFIG",
                data.LADY_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">STTT_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.STTT_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                STTT_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "STTT_BANK_CONFIG",
                data.STTT_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">TAIXIU_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_BANK_CONFIG",
                data.TAIXIU_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">KYLAN_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.KYLAN_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                KYLAN_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "KYLAN_BANK_CONFIG",
                data.KYLAN_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">SLOT_MINI_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.SLOT_MINI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                SLOT_MINI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "SLOT_MINI_BANK_CONFIG",
                data.SLOT_MINI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THANTAI_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THANTAI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THANTAI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THANTAI_BANK_CONFIG",
                data.THANTAI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">THUYCUNG_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.THUYCUNG_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                THUYCUNG_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "THUYCUNG_BANK_CONFIG",
                data.THUYCUNG_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">UPDOWN_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.UPDOWN_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                UPDOWN_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "UPDOWN_BANK_CONFIG",
                data.UPDOWN_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            UPDOWN_MINI_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.UPDOWN_MINI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                UPDOWN_MINI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "UPDOWN_MINI_BANK_CONFIG",
                data.UPDOWN_MINI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            PIRATEKING_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PIRATEKING_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PIRATEKING_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PIRATEKING_BANK_CONFIG",
                data.PIRATEKING_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">NATRA_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.NATRA_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                NATRA_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "NATRA_BANK_CONFIG",
                data.NATRA_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            POKER_MINI_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.POKER_MINI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                POKER_MINI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "POKER_MINI_BANK_CONFIG",
                data.POKER_MINI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">BATUOC_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.BATUOC_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                BATUOC_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "BATUOC_BANK_CONFIG",
                data.BATUOC_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">AVENGER_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.AVENGER_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                AVENGER_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "AVENGER_BANK_CONFIG",
                data.AVENGER_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">SICBO_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.SICBO_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                SICBO_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "SICBO_BANK_CONFIG",
                data.SICBO_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">MAYBAY_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.MAYBAY_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                MAYBAY_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "MAYBAY_BANK_CONFIG",
                data.MAYBAY_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            TAIXIU_MINI_BANK_CONFIG
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.TAIXIU_MINI_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                TAIXIU_MINI_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "TAIXIU_MINI_BANK_CONFIG",
                data.TAIXIU_MINI_BANK_CONFIG
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">ICE_BANK_CONFIG</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.ICE_BANK_CONFIG?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                ICE_BANK_CONFIG: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo("ICE_BANK_CONFIG", data.ICE_BANK_CONFIG);
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Table, Input, Select, Button, DatePicker } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat } from "../../utils";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";
const { Option } = Select;
export default function PaymentCardKhoPage() {
  const [dataTable, setDataTable] = useState([]);
  const [sum, setSum] = useState([]);
  const [filter, setFilter] = useState({
    timeFrom: "",
    timeTo: "",
  });
  const [form] = Form.useForm();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "NHÀ MẠNG",
      dataIndex: "nhaMang",
      key: "nhaMang",
      width: 140,
      align: "center",
    },
    {
      title: "MỆNH GIÁ",
      dataIndex: "menhGia",
      key: "menhGia",
      width: 140,
      render: (data) => priceFormat(data),
      align: "center",
    },

    {
      title: "MÃ THẺ",
      dataIndex: "cardCode",
      key: "cardCode",
      width: 200,
    },
    {
      title: "SERI",
      dataIndex: "cardSeri",
      key: "cardSeri",
      width: 200,
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      key: "status",
      width: 200,
      align: "center",
      render: (data) => {
        switch (data) {
          case 1:
            return <p className="text-green-400	font-semibold">Còn</p>;

          default:
            return <p className="text-red-600	">Hết</p>;
        }
      },
    },

    {
      title: "MÃ GIAO DỊCH",
      dataIndex: "orderid",
      key: "orderid",
      width: 180,
      align: "center",
    },

    {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 180,
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY")}</p>
      ),
    },
  ];

  const columnsSum = [
    {
      title: "SL Sử dụng",
      dataIndex: "slsudung",
      key: "slsudung",
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "SL Còn",
      dataIndex: "slcon",
      key: "slcon",
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "SL Tổng",
      dataIndex: "sltong",
      key: "sltong",
      render: (data) => priceFormat(data),
      align: "center",
    },

    {
      title: "Tổng sử dụng",
      dataIndex: "tongsudung",
      key: "tongsudung",
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "Tổng còn",
      dataIndex: "tongcon",
      key: "tongcon",
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "Tổng",
      dataIndex: "tong",
      key: "tong",
      render: (data) => priceFormat(data),
      align: "center",
    },
  ];

  async function getPaymentCardKhoList() {
    try {
      const data = await APIService.paymentCardKhoList(
        filter.timeFrom,
        filter.timeTo
      );
      if (data) {
        let objSum = {
          slsudung: 0,
          slcon: 0,
          sltong: 0,
          tongsudung: 0,
          tongcon: 0,
          tong: 0,
        };
        data.map((item) => {
          objSum.tong += parseFloat(item.menhGia);
          if (item.status == 1) {
            objSum.slcon += 1;
            objSum.tongcon += parseFloat(item.menhGia);
          } else {
            objSum.slsudung += 1;
            objSum.tongsudung += parseFloat(item.menhGia);
          }
          objSum.sltong += 1;
        });
        setDataTable(data);
        setSum([objSum]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getPaymentCardKhoList();
  }, []);

  async function buyCard(values) {
    try {
      const data = await APIService.buyCard(
        values.nhamang,
        values.menhgia,
        values.soluong
      );
      if (data) getPaymentCardKhoList();
    } catch (error) {
      console.error(error);
    }
  }
  const onFinish = (values) => {
    buyCard(values);
  };

  return (
    <>
      <div className="p-6 border-y-[1px]">
        <p className="text-lg font-semibold">Nhập kho thẻ</p>
        <br></br>
        <Form
          layout="vertical"
          form={form}
          className="flex w-full  form-search-filer flex-col "
          onFinish={onFinish}
        >
          <Form.Item
            name="nhamang"
            label="Nhà mạng"
            rules={[{ required: true, message: "Chưa chọn nhà mạng" }]}
          >
            <Select placeholder="Nhà mạng">
              <Option value="VT">Viettel</Option>
              <Option value="Vina">Vinaphone</Option>
              <Option value="Mobi">Mobifone</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Mệnh giá</p>}
            name="menhgia"
            rules={[{ required: true, message: "Chưa nhập mệnh giá" }]}
            place
          >
            <Input
              type="number"
              className="w-full flex-1"
              placeholder="Mệnh giá"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">Số lượng</p>}
            name="soluong"
            rules={[{ required: true, message: "Chưa nhập số lượng" }]}
          >
            <Input
              type="number"
              className="w-full flex-1"
              placeholder="Số lượng"
            />
          </Form.Item>
          <Form.Item>
            <div className="flex flex-1 justify">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                Thêm vào
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <FormSearchFilter
        title="Kho thẻ"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          console.log(value);
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format("YYYY-MM-DD"),
              timeTo: moment(value.time[1]).format("YYYY-MM-DD"),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>
      <Table columns={columns} dataSource={dataTable} />
      <Table columns={columnsSum} dataSource={sum} />
    </>
  );
}

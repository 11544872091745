import React, { useState, useEffect } from "react";
import { Form, Table, Input, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import { priceFormat, isCSKH } from "../../utils";
import { useSelector } from "react-redux";

export default function PaymentCardNapPage() {
  const user = useSelector((state) => state.user.userState);
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    inputUserId: "",
    inputSeri: "",
    inputCode: "",
    inputAmount: "",
    inputStatus: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width: 140,
      align: "center",
    },
    {
      title: "TIỀN USER",
      dataIndex: "gold",
      key: "gold",
      width: 140,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "TIỀN NẠP",
      dataIndex: "updateGold",
      key: "updateGold",
      width: 140,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "MÃ THẺ",
      dataIndex: "cardCode",
      key: "cardCode",
      width: 200,
      // align: 'center',
    },
    {
      title: "SERI",
      dataIndex: "cardSeri",
      key: "cardSeri",
      width: 200,
      // align: 'center',
    },
    {
      title: "MỆNH GIÁ",
      dataIndex: "menhGia",
      key: "menhGia",
      width: 140,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "NHÀ MẠNG",
      dataIndex: "nhaMang",
      key: "nhaMang",
      width: 140,
      align: "center",
    },
    {
      title: "CHI TIẾT",
      dataIndex: "detail",
      key: "detail",
      width: 300,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      key: "status",
      width: 140,
      align: "center",
      render: (data) => (data == 0 ? "Chờ" : "Thành công"),
    },
    {
      title: "NỀN TẢNG",
      dataIndex: "platform",
      key: "platform",
      width: 140,
      align: "center",
    },
    {
      title: "IP",
      dataIndex: "IP",
      key: "IP",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 180,
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];

  async function getPaymentCardNapList() {
    try {
      const data = await APIService.paymentCardNapList(
        filter.inputUserId,
        filter.inputSeri,
        filter.inputCode,
        filter.inputAmount,
        filter.inputStatus
      );
      if (data) {
        // cskh chỉ cho phép xem trong n ngày gần nhất
        if (isCSKH(user)) {
          let to = new Date();
          to.setHours(23);
          to.setMinutes(59);
          to.setSeconds(59);
          let from = new Date();
          from.setDate(
            to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER
          );
          from.setHours(0);
          from.setMinutes(0);
          from.setSeconds(0);
          for (let i = 0; i < data.length; i++) {
            let createdDate = moment(data[i].timestamp);
            let limitDate = moment(from);
            if (createdDate.isBefore(limitDate)) {
              data.splice(i, 1);
              i--;
            }
          }
        }
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getPaymentCardNapList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
        title="Thẻ Cào"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          setFilter({ ...filter, ...value });
        }}
      >
        <Form.Item
          label="UserID"
          className="form-item-filter"
          name="inputUserId"
        >
          <Input className="w-full" />
        </Form.Item>
        <Form.Item
          label="Card Code"
          className="form-item-filter"
          name="inputCode"
        >
          <Input className="w-full" />
        </Form.Item>
        <Form.Item
          label="Card Seri"
          className="form-item-filter"
          name="inputSeri"
        >
          <Input className="w-full" />
        </Form.Item>
        <Form.Item
          label="Mệnh Giá được"
          className="form-item-filter"
          name="inputAmount"
        >
          <Input className="w-full" />
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Table,
  Input,
  Tooltip,
  Button,
  Drawer,
  Tabs,
  InputNumber,
  DatePicker,
  notification,
} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/lib/input/TextArea";
const dateFormatPicker = "DD-MM-YYYY hh:mm";
export default function PaymentCodeAddPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const userInfoList = useRef();
  const [form] = Form.useForm();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 200,
    },
    {
      title: "MIN",
      dataIndex: "minLevel",
      key: "minLevel",
      width: 100,
      align: "center",
      // render: (data) => priceFormat(data),
    },
    {
      title: "LÚC",
      dataIndex: "startedAt",
      key: "startedAt",
      align: "center",
      width: 140,
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "THỜI HẠN",
      dataIndex: "expiredAt",
      key: "expiredAt",
      align: "center",
      width: 140,
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "NUMBER",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: 140,
    },
    {
      title: "TIỀN",
      dataIndex: "goldGet",
      key: "goldGet",
      width: 140,
      render: (data) => priceFormat(data),
    },
    {
      title: "LOẠI",
      dataIndex: "type",
      width: 140,
      align: "center",
      key: "type",
    },
  ];

  async function getPaymentCodeList() {
    try {
      const data = await APIService.paymentCodeList(
        filter.code,
        filter.type,
        filter.money,
        filter.number,
        filter.expired
      );
      if (data) {
        console.log(data);
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getPaymentCodeList();
  }, [filter]);

  const onFinish = (values) => {
    getCreateGiftcode(values.code, values.number, values.goldGet, 1, values.type, '2022-01-01', values.expiredAt);
  };

  const onFinishCodeRandom = (values) => {
    getCreateCodeRandom(values.prefix, values.suffix, values.length, values.number, values.goldGet, 1, values.type, '2022-01-01', values.expiredAt);
  };


  async function getCreateGiftcode(code, number, goldGet, minLevel, type, startedAt, expiredAt) {
    try {
      const data = await APIService.createGiftCode(code, number, goldGet, minLevel, type, startedAt, expiredAt);
      if (data) {
        notification['success']({
          message: 'Thành công',
          description:
            'GiftCode đã được tạo.',
        });
        getPaymentCodeList()
        form.resetFields();
        setOpenCreate(false)
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCreateCodeRandom(prefix, suffix, length, number, goldGet, minLevel, type, startedAt, expiredAt) {
    try {
      const data = await APIService.createCodeRandom(prefix, suffix, length, number, goldGet, minLevel, type, startedAt, expiredAt);
      if (data) {
        notification['success']({
          message: 'Thành công',
          description:
            'GiftCode đã được tạo.',
        });
        getPaymentCodeList()
        form.resetFields();
        setOpenCreate(false)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const items = [
    {
      key: "1",
      label: `Thủ công`,
      children: (
        <Form
          layout="vertical"
          form={form}
          className="flex w-full  form-search-filer flex-col "
          onFinish={onFinish}
        >
          <Form.Item label={<p className="font-bold ">Mã Code</p>} name="code">
            <Input
              placeHolder="Gift Code là mã gồm chữ in hoa và số"
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Số lượng nhận</p>}
            name="number"
          >
            <Input className="w-full flex-1" />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">Xu nhận</p>}
            name="goldGet"
          >
            <Input className="w-full flex-1" />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">Thời gian hết hạn</p>}
            name="expiredAt"
          >
            <DatePicker
              format={dateFormatPicker}
              showTime
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item label={<p className="font-bold">Loại Code</p>} name="type">
            <Input placeHolder="Vd: 1" className="w-full flex-1" />
          </Form.Item>
          <Form.Item>
            <div className="flex flex-1 justify-end">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                TẠO GIFTCODE
              </Button>
            </div>
          </Form.Item>
        </Form>
      ),
    },
    {
      key: "2",
      label: `Ngẫu nhiên`,
      children: (
        <Form
          layout="vertical"
          form={form}
          className="flex w-full  form-search-filer flex-col "
          onFinish={onFinishCodeRandom}
        >
          <Form.Item
            label={<p className="font-bold">Khúc đầu Mã code</p>}
            name="prefix"
          >
            <Input
              placeHolder="Gồm chữ in hoa hoặc số (không bắt buộc)"
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Khúc sau Mã code</p>}
            name="suffix"
          >
            <Input
              placeHolder="Gồm chữ in hoa hoặc số (không bắt buộc)"
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Chiều dài Mã code</p>}
            name="length"
          >
            <InputNumber min={1} className="w-full flex-1 flex-start" />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Số lượng nhận</p>}
            name="number"
          >
            <InputNumber min={1} className="w-full flex-1" />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Xu nhận</p>}
            name="goldGet"
          >
            <InputNumber min={1} className="w-full flex-1" />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Thời gian hết hạn</p>}
            name="expiredAt"
          >
            <DatePicker
              format={dateFormatPicker}
              showTime
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Loại Code</p>}
            name="type"
          >
            <Input placeHolder="Vd: 1" className="w-full flex-1" />
          </Form.Item>
          <Form.Item>
            <div className="flex flex-1 justify-end">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                TẠO GIFTCODE
              </Button>
            </div>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <>
      <FormSearchFilter title="Thêm" initialValues={filter}>
        <div></div>
        <div></div>
        <div></div>
        <div className="flex   justify-end items-end ">
          <Button
            onClick={() => {
              setOpenCreate(true);
            }}
            className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6"
          >
            TẠO GIFTCODE
          </Button>
        </div>
      </FormSearchFilter>
      <Table columns={columns} dataSource={dataTable} />
      <Drawer
        closable={false}
        placement="right"
        onClose={() => {
          setOpenCreate(false);
        }}
        open={openCreate}
        width={550}
        bodyStyle={{ padding: 0 }}
      >
        <div className="py-3 px-5 flex justify-between items-center bg-[#F8F8F8]">
          Thông tin tạo mới
          <Button
            type="link"
            onClick={() => {
              setOpenCreate(false);
            }}
          >
            <FontAwesomeIcon
              icon={faXmark}
              style={{ fontSize: "18px", color: "black" }}
            />
          </Button>
        </div>
        <div className="p-5">
          <Tabs defaultActiveKey="1" items={items} />
        </div>
      </Drawer>
    </>
  );
}

import { Button, Form, Input, Radio } from "antd";

export default function FormSearchFilter({ title="Search & Filter",children, onFormLayoutChange, initialValues }) {
  const [form] = Form.useForm();
  return (
    <div className="p-6 border-y-[1px]">
      <p className="text-lg font-semibold">{title}</p>
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        className="flex flex-row mt-4 form-search-filer grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2"
        onValuesChange={onFormLayoutChange}
      >
        {children}
      </Form>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip, Button, Modal } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { strUcfirst } from "../../utils";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";

export default function ConfigUpdateGamePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <FormSearchFilter title="Update Game"></FormSearchFilter>
      <div class=" m-8 flex-1.2 grid grid-flow-row-dense grid-cols-2 grid-rows-2 gap-8">
        <div class="">
          <p className="text-base mr-4 font-medium">Cập nhật toàn bộ payment</p>
        </div>
        <div>
          <Button
            onClick={() => {
              setIsModalOpen("PAYMENT");
            }}
            className="w-40"
            type="primary"
          >
            Update Payment
          </Button>
        </div>
        <div class="">
          <p className="text-base mr-4 font-medium">
            Cập nhật toàn bộ thông tin user
          </p>
        </div>

        <div>
          <Button
            onClick={() => {
              setIsModalOpen("ACCOUNT");
            }}
            className="w-40"
            type="primary"
          >
            Update Account
          </Button>
        </div>
      </div>

      <Modal
        title="Xác nhận"
        open={!!isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <p>
          Update {isModalOpen == "PAYMENT" ? "payment" : "account"} toàn bộ user
          trên server?
        </p>
      </Modal>
    </>
  );
}

import { post, get, execute } from "./AxiosInstance";
import moment from "moment";
const ARRAY_STATISTIC = [
  "Tiến Lên",
  "Mậu Binh",
  "Poker",
  "Xì Dách",
  "Bài Cào",
  "Xóc Đĩa",
  "Bầu Cua",
  "Liêng",
  "Sâm",
  "Tai Xiu",
  "Baccarat",
  "Tài Xỉu Mini",
  "Poker Mini",
  "Cao Thấp",
  "Bắn Cá",
  "Black Jack",
  "Slot DaoCa",
  "Slot WuKong",
  "Slot MetaGear",
  "Slot GodOfWar",
  "Slot FootBall",
  "Slot Lady",
  "Slot STTT",
  "Nạp Card",
  "Đổi Card",
  "Nạp Code",
  "Đổi Code",
  "Nạp Bank",
  "Đổi Bank",
  "Nạp IAP",
  "Nạp Ads",
  "Nạp Momo",
  "Đổi Momo",
  "Reward Item",
  "Nhiệm vụ",
  "BackOffice",
  "Khác",
];
let ARRAY_PLATFORM = ["AND", "IOS", "WIN", "WEB", "APK"];

let ARRAY_ACTION = [
  "JOIN_WEB",
  "LOGIN_FALSE",
  "LOGIN_SUCCESS",
  "REGISTER_FALSE",
  "REGISTER_SUCCESS",
];
let ARRAY_DAU = [1, 2, 3, 4, 5, 6, 7, 30];
let ARRAY_STATISTIC_OTHER = "Khác";
async function login(username, password) {
  try {
    let body = {};
    body.username = username;
    body.password = password;
    const response = await post("/admin-login", {}, body);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function userInfoList() {
  try {
    let params = {};
    const response = await get("/bo/user/listnew?num=1000", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}
async function paymentCardThongKeKhoList() {
  try {
    let params = {};
    const response = await get("/bo/pm/wd/sumBunker", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentCodeNapList() {
  try {
    let params = {};
    params.column = "payment_code_log";
    params.where = `type=0 order by id desc limit 1000`;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentBankDuyetList() {
  try {
    var sql = `1=1 and type=1 and duyetTay<>0 order by id desc limit 1000 `;
    let params = {};
    params.column = "payment_bank_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentMomoDuyetList() {
  try {
    var sql = `1=1 and type=1 and duyetTay<>0 order by id desc limit 1000 `;
    let params = {};
    params.column = "payment_momo_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}
async function paymentBankNapList() {
  try {
    var sql = "type=0";
    sql += ` order by id desc limit 1000 `;
    let params = {};
    params.column = "payment_bank_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}
async function paymentMomoNapList() {
  try {
    var sql = "type=0";
    sql += ` order by id desc limit 1000 `;
    let params = {};
    params.column = "payment_momo_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentBankDoiList() {
  try {
    var sql = "type=1";
    sql += ` order by id desc limit 1000 `;
    let params = {};
    params.column = "payment_bank_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}
async function paymentMomoDoiList() {
  try {
    var sql = "type=1";
    sql += ` order by id desc limit 1000 `;
    let params = {};
    params.column = "payment_momo_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentCodeDoiList() {
  try {
    let params = {};
    params.column = "payment_code_log";
    params.where = `type=1 order by id desc limit 1000`;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentCodeList(code, type, money, number, expired) {
  try {
    let params = {};
    params.code = code;
    params.type = type;
    params.money = money;
    params.number = number;
    params.expired = expired;
    const response = await get("/bo/giftcode/get", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentCardKhoList(from, to) {
  try {
    var sql = "";
    if (from == "" || to == "") {
      sql = `1=1 order by id desc limit 1000`;
    } else {
      sql = `'${from} 00:00:00'<=createdAt and createdAt<'${to} 00:00:00' order by id desc`;
    }
    let params = {};
    params.column = "exchange_bunker";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentCardDuyetList() {
  try {
    var sql = `1=1 and type=1 order by id desc limit 1000 `;
    let params = {};
    params.column = "payment_card_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentCardNapList(
  inputUserId,
  inputSeri,
  inputCode,
  inputAmount,
  inputStatus
) {
  try {
    var sql = "";
    if (inputUserId.length > 0) {
      sql += `player='${inputUserId}' `;
    } else {
      sql += `1=1 `;
    }
    if (inputCode.length > 0) {
      if (sql.length > 0) sql += ` and `;
      sql += `cardCode='${inputCode}' `;
    }
    if (inputSeri.length > 0) {
      if (sql.length > 0) sql += ` and `;
      sql += `cardSeri='${inputSeri}' `;
    }
    if (inputAmount.length > 0) {
      if (sql.length > 0) sql += ` and `;
      sql += `menhGia=${inputAmount} `;
    }
    if (inputStatus.length > 0) {
      if (sql.length > 0) sql += ` and `;
      sql += `status=${inputStatus} `;
    }
    sql = `${sql} order by id desc limit 100 `;
    let params = {};
    params.column = "payment_card_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function searchUserInfo(userid, keyword) {
  try {
    let params = {};
    params.userid = userid;
    params.keyword = keyword;
    const response = await get("/bo/user/find", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function mailList(player) {
  try {
    let params = {};
    params.player = player;
    const response = await get("/bo/user/mail", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function createMail(player, title, content) {
  try {
    let params = {};
    params.player = player;
    params.type = 0;
    params.sender = "Admin";
    params.title = title;
    params.content = content;

    const response = await get("/bo/user/mail-create", params);
    const data = await execute(response, false);

    return data;
  } catch (error) {
    console.error(error);
  }
}

async function createGiftCode(
  code,
  number,
  goldGet,
  minLevel,
  type,
  startedAt,
  expiredAt
) {
  try {
    let params = {};
    params.code = code;
    params.number = number;
    params.goldGet = goldGet;
    params.minLevel = minLevel;
    params.type = type;
    params.startedAt = moment(startedAt).format("YYYY-MM-DD");
    params.expiredAt = moment(expiredAt).format("YYYY-MM-DD");
    const response = await get("/bo/giftcode/create", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function createBroadcast(content, numAppear, timeFrom, timeTo) {
  try {
    let params = {};
    params.content = content;
    params.numAppear = numAppear;
    params.timeFrom = timeFrom;
    params.timeTo = timeTo;
    const response = await get("/bo/broadcast/create", params);
    // const data = await execute(response, false);
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function createCodeRandom(
  prefix,
  suffix,
  length,
  number,
  goldGet,
  minLevel,
  type,
  startedAt,
  expiredAt
) {
  try {
    let params = {};
    params.prefix = prefix;
    params.suffix = suffix;
    params.length = length;
    params.number = number;
    params.goldGet = goldGet;
    params.minLevel = minLevel;
    params.type = type;
    params.startedAt = moment(startedAt).format("YYYY-MM-DD");
    params.expiredAt = moment(expiredAt).format("YYYY-MM-DD");
    const response = await get("/bo/giftcode/random", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function moneyLogList(player, timeFrom, timeTo) {
  try {
    var sql = "";
    if (player.length > 0) {
      sql += `player='${player}'`;
    } else {
      sql += `1=1`;
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      sql += ` and '${timeFrom} 00:00:00'<=time and time<'${timeTo} 23:59:59'`;
    } else {
      sql += " order by id desc limit 1000";
    }
    let params = {};
    params.column = "player_money_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function slotLogList(player, timeFrom, timeTo) {
  try {
    var sql = "";
    if (player.length > 0) {
      sql += `player='${player}'`;
    } else {
      sql += `1=1`;
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      let timestampFrom = moment(timeFrom, "YYYY-MM-DD");
      let timestampTo = moment(timeTo, "YYYY-MM-DD");
      sql += ` and '${timestampFrom.unix()}'<=timestamp and timestamp<'${timestampTo.unix()}'`;
    } else {
      sql += ` order by id desc limit 1000`;
    }
    let params = {};
    params.column = "slot_game_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function fishLogDetailList(player, timeFrom, timeTo) {
  try {
    var sql = "";
    if (player.length > 0) {
      sql += `player='${player}'`;
    } else {
      sql += `1=1`;
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      sql += ` and '${timeFrom}'<=createdAt and createdAt<'${timeTo}'`;
    } else {
      sql += ` order by id desc limit 1000`;
    }
    let params = {};
    params.column = "player_fish_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function statisTrackingList(from, to, requestType) {
  try {
    if (from === "" || to === "") {
      to = Math.floor(new Date().getTime() / 1000);
      from = to - 30 * 24 * 60 * 60; // 30 ngày
      let time = moment(from * 1000);
      time.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      from = time.unix();
    } else {
      if (isNaN(from) || isNaN(to)) {
        from = moment(from, "YYYY-MM-DD");
        from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        from = from.unix();
        to = moment(to, "YYYY-MM-DD");
        to.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
        to = to.unix();
      }
    }
    //check trùng 1 ngày
    if (from + 24 * 60 * 60 > to) {
      to = from;
    }
    let params = {};
    params.from = from;
    params.to = to;
    const response = await get("/bo/statitic/tracking", params);
    let body = response.data;
    let index = 0;
    let array = [];
    let momentTo = moment(to * 1000);
    momentTo = momentTo.format("YYYY-MM-DD");
    //lấy danh sách request type
    let listKey = [];
    let listRequestType = [];

    for (let i = 0; i < body.length; i++) {
      let find = false;
      for (let j = 0; j < listKey.length; j++) {
        if (body[i]["requestType"] === listKey[j]) {
          find = true;
        }
      }
      if (!find) {
        const text = body[i]["requestType"];
        listKey.push(text);
        let label = text;
        if (label === "")
          label = "(Rỗng)";
        const tmp = {
          "label": label,
          "value": text
        }
        listRequestType.push(tmp);
      }
    }

    while (true) {
      let momentFrom = 0;
      if (to === from) {
        momentFrom = index + 1;
        if (index + 1 < 10) momentFrom = index + 1;
        let per = {};
        per["id"] = index;
        per["days"] = momentFrom + " giờ";
        per[requestType] = [
          { name: "AND", sum: 0, arr: [0, 0, 0, 0, 0] },
          { name: "IOS", sum: 0, arr: [0, 0, 0, 0, 0] },
          { name: "WIN", sum: 0, arr: [0, 0, 0, 0, 0] },
          { name: "WEB", sum: 0, arr: [0, 0, 0, 0, 0] },
          { name: "APK", sum: 0, arr: [0, 0, 0, 0, 0] },
        ];
        for (let i = 0; i < listKey.length; i++) {
          per[listKey[i]] = [
            { name: "AND", sum: 0, arr: [0, 0, 0, 0, 0] },
            { name: "IOS", sum: 0, arr: [0, 0, 0, 0, 0] },
            { name: "WIN", sum: 0, arr: [0, 0, 0, 0, 0] },
            { name: "WEB", sum: 0, arr: [0, 0, 0, 0, 0] },
            { name: "APK", sum: 0, arr: [0, 0, 0, 0, 0] },
          ];
        }
        for (let i = 0; i < body.length; i++) {
          if (body[i].days === momentFrom) {
            let nameGame = body[i].requestType;
            let indexPlatform = ARRAY_PLATFORM.indexOf(body[i].platform);
            let indexAction = ARRAY_ACTION.indexOf(body[i].action);
            per[nameGame][indexPlatform].sum += body[i].soluong;
            per[nameGame][indexPlatform].arr[indexAction] = body[i].soluong;
          }
        }
        array.push(per);
        if (momentFrom === 24) {
          break;
        }
      } else {
        momentFrom = moment((from + index * 24 * 60 * 60) * 1000);
        momentFrom = momentFrom.format("YYYY-MM-DD");
        let per = {};
        per["id"] = index;
        per["days"] = moment(momentFrom).format("DD-MM-YYYY");
        per[requestType] = [
          { name: "AND", sum: 0, arr: [0, 0, 0, 0, 0] },
          { name: "IOS", sum: 0, arr: [0, 0, 0, 0, 0] },
          { name: "WIN", sum: 0, arr: [0, 0, 0, 0, 0] },
          { name: "WEB", sum: 0, arr: [0, 0, 0, 0, 0] },
          { name: "APK", sum: 0, arr: [0, 0, 0, 0, 0] },
        ];
        for (let i = 0; i < listKey.length; i++) {
          per[listKey[i]] = [
            { name: "AND", sum: 0, arr: [0, 0, 0, 0, 0] },
            { name: "IOS", sum: 0, arr: [0, 0, 0, 0, 0] },
            { name: "WIN", sum: 0, arr: [0, 0, 0, 0, 0] },
            { name: "WEB", sum: 0, arr: [0, 0, 0, 0, 0] },
            { name: "APK", sum: 0, arr: [0, 0, 0, 0, 0] },
          ];
        }
        for (let i = 0; i < body.length; i++) {
          if (body[i].days === momentFrom) {
            let nameGame = body[i].requestType;
            let indexPlatform = ARRAY_PLATFORM.indexOf(body[i].platform);
            let indexAction = ARRAY_ACTION.indexOf(body[i].action);
            per[nameGame][indexPlatform].sum += body[i].soluong;
            per[nameGame][indexPlatform].arr[indexAction] = body[i].soluong;
          }
        }
        array.push(per);
        if (momentFrom === momentTo) {
          break;
        }
      }
      index++;
    }
    return {
      "keys": listRequestType,
      "data": array
    }
  } catch (error) {
    console.error(error);
  }
}

async function statisDAUList(from, to) {
  try {
    if (from == "" || to == "") {
      to = Math.floor(new Date().getTime() / 1000);
      from = to - 30 * 24 * 60 * 60; // 30 ngày
      let time = moment(from * 1000);
      time.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      from = time.unix();
    } else {
      if (isNaN(from) || isNaN(to)) {
        from = moment(from, "YYYY-MM-DD");
        from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        from = from.unix();
        to = moment(to, "YYYY-MM-DD");
        to.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
        to = to.unix();
      }
    }
    //check trùng 1 ngày
    if (from + 24 * 60 * 60 > to) {
      to = from;
    }
    let params = {};
    params.from = from;
    params.to = to;
    const response = await get("/bo/statitic/dau", params);
    let body = response.data;
    let index = 0;
    let array = [];
    let momentTo = moment(to * 1000);
    momentTo = momentTo.format("YYYY-MM-DD");
    while (true) {
      let momentFrom = 0;
      if (to == from) {
        momentFrom = index + 1;
        if (index + 1 < 10) momentFrom = index + 1;
        let per = {};
        per["id"] = index;
        per["days"] = momentFrom + " giờ";
        for (let i = 0; i < ARRAY_DAU.length; i++) {
          per[ARRAY_DAU[i]] = 0;
        }
        for (let i = 0; i < body.length; i++) {
          if (body[i].days == momentFrom) {
            let nameGame = body[i].countLoginPerDay;
            if (ARRAY_DAU.indexOf(nameGame) >= 0) {
              per[nameGame] = body[i].dau;
            } else {
              per[ARRAY_STATISTIC_OTHER] = body[i].dau;
            }
          }
        }
        array.push(per);
        if (momentFrom === 24) {
          break;
        }
      } else {
        momentFrom = moment((from + index * 24 * 60 * 60) * 1000);
        momentFrom = momentFrom.format("YYYY-MM-DD");
        let per = {};
        per["id"] = index;
        per["days"] = momentFrom;
        for (let i = 0; i < ARRAY_DAU.length; i++) {
          per[ARRAY_DAU[i]] = 0;
        }
        for (let i = 0; i < body.length; i++) {
          if (body[i].days == momentFrom) {
            let nameGame = body[i].countLoginPerDay;
            if (ARRAY_DAU.indexOf(nameGame) >= 0) {
              per[nameGame] = body[i].dau;
            } else {
              per[ARRAY_STATISTIC_OTHER] = body[i].dau;
            }
          }
        }
        array.push(per);
        if (momentFrom === momentTo) {
          break;
        }
      }
      index++;
    }
    return array;
  } catch (error) {
    console.error(error);
  }
}

async function statisGoldList(from, to) {
  try {
    if (from === "" || to === "") {
      to = Math.floor(new Date().getTime() / 1000);
      from = to - 30 * 24 * 60 * 60; // 30 ngày
      let time = moment(from * 1000);
      time.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      from = time.unix();
    } else {
      if (isNaN(from) || isNaN(to)) {
        from = moment(from, "YYYY-MM-DD");
        from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        from = from.unix();
        to = moment(to, "YYYY-MM-DD");
        to.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
        to = to.unix();
      }
    }
    //check trùng 1 ngày
    if (from + 24 * 60 * 60 > to) {
      to = from;
    }
    let params = {};
    params.from = from;
    params.to = to;
    const response = await get("/bo/statitic/gold-log", params);
    let body = response.data;
    let index = 0;
    let type = 1; // ngày
    let array = [];
    let momentTo = moment(to * 1000);
    momentTo = momentTo.format("YYYY-MM-DD");
    while (true) {
      let momentFrom = 0;
      if (to === from) {
        type = 2; // giờ
        momentFrom = index + 1;
        if (index + 1 < 10) momentFrom = index + 1;
        let per = {};
        per["id"] = index;
        per["days"] = momentFrom + " giờ";
        for (let i = 0; i < ARRAY_STATISTIC.length; i++) {
          per[ARRAY_STATISTIC[i]] = 0;
        }
        for (let i = 0; i < body.length; i++) {
          if (body[i].days === momentFrom) {
            let nameGame = body[i].game;
            if (ARRAY_STATISTIC.indexOf(nameGame) >= 0) {
              per[nameGame] = body[i].sumGold;
            } else {
              per[ARRAY_STATISTIC_OTHER] = body[i].sumGold;
            }
          }
        }
        array.push(per);
        if (momentFrom === 24) {
          break;
        }
      } else {
        momentFrom = moment((from + index * 24 * 60 * 60) * 1000);
        momentFrom = momentFrom.format("YYYY-MM-DD");

        let per = {};
        per["id"] = index;
        per["days"] = momentFrom;
        for (let i = 0; i < ARRAY_STATISTIC.length; i++) {
          per[ARRAY_STATISTIC[i]] = 0;
        }
        for (let i = 0; i < body.length; i++) {
          if (body[i].days === momentFrom) {
            let nameGame = body[i].game;
            if (ARRAY_STATISTIC.indexOf(nameGame) >= 0) {
              per[nameGame] = body[i].sumGold;
            } else {
              per[ARRAY_STATISTIC_OTHER] = body[i].sumGold;
            }
          }
        }
        array.push(per);
        if (momentFrom === momentTo) {
          break;
        }
      }
      index++;
    }
    let object = {
      "type": type,
      "array": array
    }
    return object;
  } catch (error) {
    console.error(error);
  }
}

async function fishLogList(player, timeFrom, timeTo) {
  try {
    var sql = "";
    if (player.length > 0) {
      sql += `player='${player}'`;
    } else {
      sql += `1=1`;
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      let timestampFrom = moment(timeFrom, "YYYY-MM-DD");
      let timestampTo = moment(timeTo, "YYYY-MM-DD");
      sql += ` and '${timestampFrom.unix()}'<=loginTime and loginTime<'${timestampTo.unix()}'`;
    } else {
      sql += ` order by id desc limit 1000`;
    }
    let params = {};
    params.column = "fish_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function gamgLogList(player, timeFrom, timeTo) {
  try {
    var sql = "";
    if (player.length <= 0) {
      sql = " 1=1 ";
    } else {
      sql = `content like '%\"userid\":\"${player}\"%'`;
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      sql += ` and startTime >= '${timeFrom}' and startTime < '${timeTo}'`;
    } else {
      sql += ` order by id desc limit 1000`;
    }
    let params = {};
    params.column = "game_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function gameBetLogList(player, timeFrom, timeTo) {
  try {
    var sql = "";
    if (player.length <= 0) {
      sql = " 1=1 ";
    } else {
      sql = `player = "${player}"`;
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      sql += ` and timeStart >= '${timeFrom}' and timeStart < '${timeTo}'`;
    } else {
      sql += ` order by id desc limit 1000`;
    }
    let params = {};
    params.column = "player_history_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function jackpotLogList(player, timeFrom, timeTo) {
  try {
    var sql = `player='${player}'`;
    if (player.length <= 0) {
      sql = " 1=1 ";
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      let timestampFrom = moment(timeFrom, "YYYY-MM-DD");
      let timestampTo = moment(timeTo, "YYYY-MM-DD");
      sql += ` and '${timestampFrom.unix()}'<=timestamp and timestamp<'${timestampTo.unix()}' order by id desc`;
    } else {
      sql += ` order by id desc limit 1000`;
    }
    let params = {};
    params.column = "jackpot_game_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function jackpotFishLog(player, timeFrom, timeTo) {
  try {
    var sql = `player='${player}'`;
    if (player.length <= 0) {
      sql = " 1=1 ";
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      sql += ` and '${timeFrom}'<=createdAt and createdAt<'${timeTo}' order by id desc`;
    } else {
      sql += ` order by id desc limit 1000`;
    }
    let params = {};
    params.column = "jackpot_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function trackingLogList(player, timeFrom, timeTo) {
  try {
    var sql = `player='${player}'`;
    if (player.length <= 0) {
      sql = " 1=1 ";
    }
    if (timeFrom.length > 0 && timeTo.length > 0) {
      sql += ` and '${timeFrom}'<=createdAt and createdAt<'${timeTo}'`;
    } else {
      sql += ` order by id desc limit 1000`;
    }
    let params = {};
    params.column = "request_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function gameStockList() {
  try {
    let params = {};
    const response = await get("/bo/config/game-stock", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateGameStock(id, currentStock) {
  try {
    let params = {};
    params.id = id;
    params.currentStock = currentStock;
    const response = await get("/bo/config/update-game-stock", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function configPaymentInfo() {
  try {
    let params = {};
    const response = await get("/bo/config/get-payment", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateConfigPaymentInfo(type, config) {
  try {
    let params = {};
    params.type = type;
    params.config = config;
    const response = await get("/bo/config/update-payment", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateJackpot(id, money, pool) {
  try {
    let params = {};
    params.id = id;
    params.money = money;
    params.pool = pool;
    const response = await get("/bo/config/update-jackpot", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function jackpotLiveList() {
  try {
    let params = {};
    const response = await get("/bo/config/get-jackpot", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function configJackpotList() {
  try {
    let params = {};
    const response = await get("/bo/config/get-jackpot-info", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateConfigJackpot(
  id,
  minMoneyCanHit,
  perMoneyAddToJackpot,
  numMoneyPerSecond,
  perMoneyAddToPool,
  moneyDefault,
  moneyMaxPool,
  perMoneyWinNormal,
  timeAppear,
  timeSwim,
  perWin2PerDay
) {
  try {
    let params = {};
    params.id = id;
    params.minMoneyCanHit = minMoneyCanHit;
    params.perMoneyAddToJackpot = perMoneyAddToJackpot;
    params.numMoneyPerSecond = numMoneyPerSecond;
    params.perMoneyAddToPool = perMoneyAddToPool;
    params.moneyDefault = moneyDefault;
    params.moneyMaxPool = moneyMaxPool;
    params.perMoneyWinNormal = perMoneyWinNormal;
    params.timeAppear = timeAppear;
    params.timeSwim = timeSwim;
    params.perWin2PerDay = perWin2PerDay;
    const response = await get("/bo/config/update-jackpot-info", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function sessionLogList(player, timeFrom, timeTo) {
  try {
    var sql = `player='${player}'`;
    if (timeFrom.length > 0 && timeTo.length > 0) {
      sql += ` and '${timeFrom} 00:00:00'<loginTime and loginTime<'${timeTo} 23:59:59'`;
    } else {
      sql += " order by id desc limit 1000";
    }
    let params = {};
    params.column = "session_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function sessionLogListByIp(ip) {
  try {
    var sql = `ip='${ip}'`;
    let params = {};
    params.column = "session_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}
async function broadcastLogList() {
  try {
    var sql = `1=1`;
    sql += " order by id desc limit 1000";
    let params = {};
    params.column = "broadcast_log";
    params.where = sql;
    const response = await get("/bo/data/select", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateDetailGroup(ids, multipliers, chip_pools) {
  try {
    let body = {};
    body.ids = ids;
    body.multipliers = multipliers;
    body.chip_pools = chip_pools;
    const response = await post(
      "/plinko-2/tool-config/update-with-risk-row",
      {},
      body
    );
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateCardDuyet(orderid, type) {
  try {
    let params = {};
    params.orderid = orderid;
    params.type = type;

    const response = await get("/bo/pm/wd/card", params);
    const data = await execute(response, false);

    return data;
  } catch (error) {
    console.error(error);
  }
}

async function buyCard(NhaMang, MenhGia, SoLuong) {
  try {
    let params = {};
    params.NhaMang = NhaMang;
    params.MenhGia = MenhGia;
    params.SoLuong = SoLuong;
    const response = await get("/bo/buyCard", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateMoney(userid, money) {
  try {
    let params = {};
    params.player = userid;
    params.money = money;
    const response = await get("/bo/user/update-money", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function getUserDetail(userid) {
  try {
    let params = {};
    params.userid = userid;
    const response = await get("/bo/user/view", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function getUserPaymentStatistic(userid) {
  try {
    let params = {};
    params.userid = userid;
    const response = await get("/bo/user/payment_money_statistic", params);
    const data = await execute(response, false);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function confirmBank(orderid, type) {
  try {
    let params = {};
    params.orderid = orderid;
    params.type = type;
    const response = await get("/bo/pm/wd/bank", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function changeBankStatus(orderid, status) {
  try {
    let params = {};
    params.orderid = orderid;
    params.status = status;
    const response = await get("/bo/pm/wd/updateStatusBank", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function confirmMoMo(orderid, type) {
  try {
    let params = {};
    params.orderid = orderid;
    params.type = type;
    const response = await get("/bo/pm/wd/momo", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function changeMoMoStatus(orderid, status) {
  try {
    let params = {};
    params.orderid = orderid;
    params.status = status;
    const response = await get("/bo/pm/wd/updateStatusMomo", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function resetPass(player, pass, userbo) {
  try {
    let params = {};
    params.player = player;
    params.pass = pass;
    params.userbo = userbo;
    const response = await get("/bo/user/update", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function changeSunStatus(orderid) {
  try {
    let params = {};
    params.orderid = orderid;
    const response = await get("/bo/pm/wd/sun", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updatePlayerPayment(player) {
  try {
    let params = {};
    params.id = player;
    const response = await get("/bo/config/update-player-payment", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function withdrawSun(username, password, money) {
  try {
    let params = {};
    params.username = username;
    params.pass = password;
    params.amount = money;
    const response = await get("/bo/withdrawsun", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateStatus(player, status) {
  try {
    let params = {};
    params.player = player;
    params.status = status;
    const response = await get("/bo/user/update", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateWDAccountName(player, wdBankAccountName, wdMoMoAccountName) {
  try {
    let params = {};
    params.player = player;
    params.wdBankAccountName = wdBankAccountName;
    params.wdMoMoAccountName = wdMoMoAccountName;
    const response = await get("/bo/user/update", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function withdrawHit(username, password, money) {
  try {
    let params = {};
    params.username = username;
    params.pass = password;
    params.money = money;
    const response = await get("/bo/withdrawhit", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function changeHitStatus(orderid) {
  try {
    let params = {};
    params.orderid = orderid;
    const response = await get("/bo/pm/wd/hit", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function paymentBank(player, bankCode, bankName, amount){
  try {
    let params = {};
    params.player = player;
    params.bankCode = bankCode;
    params.bankName = bankName;
    params.amount = amount;
    const response = await get("/bo/pm/bank", params);
    const data = await execute(response, true);
    return data;
  } catch (error) {
    console.error(error);
  }
}


const APIService = {
  login,
  userInfoList,
  updateDetailGroup,
  searchUserInfo,
  mailList,
  createMail,
  moneyLogList,
  sessionLogList,
  broadcastLogList,
  trackingLogList,
  jackpotFishLog,
  jackpotLogList,
  gamgLogList,
  gameBetLogList,
  fishLogList,
  fishLogDetailList,
  slotLogList,
  statisGoldList,
  statisDAUList,
  statisTrackingList,
  gameStockList,
  jackpotLiveList,
  configJackpotList,
  paymentCardNapList,
  paymentCardDuyetList,
  paymentCardThongKeKhoList,
  paymentCardKhoList,
  paymentCodeList,
  paymentCodeNapList,
  paymentCodeDoiList,
  paymentBankNapList,
  paymentBankDoiList,
  paymentBankDuyetList,
  paymentMomoNapList,
  paymentMomoDoiList,
  paymentMomoDuyetList,
  configPaymentInfo,
  createGiftCode,
  createCodeRandom,
  updateConfigPaymentInfo,
  createBroadcast,
  updateJackpot,
  updateGameStock,
  updateConfigJackpot,
  updateCardDuyet,
  buyCard,
  updateMoney,
  getUserDetail,
  getUserPaymentStatistic,
  confirmBank,
  confirmMoMo,
  resetPass,
  changeBankStatus,
  changeMoMoStatus,
  changeSunStatus,
  updatePlayerPayment,
  withdrawSun,
  updateStatus,
  updateWDAccountName,
  withdrawHit,
  changeHitStatus,
  sessionLogListByIp,
  paymentBank
};

export default APIService;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import {Form, Input, Button, notification, Select, message} from "antd";
import APIService from "../../service/APIService";
import ButtonCopyClipboard from "../../components/ButtonCopyClipboard";

export default function UserWithDrawSunPage() {
  const [form] = Form.useForm();
  const [userID, setUserId] = useState([]);
  const [money, setMoney] = useState(20000);
  const [codePayBankCode, setCodePayBankCode] = useState('MB');
  const [isCodePayProcessing, setIsCodePayProcessing] = useState(false);
  const [dataCodePay, setDataCodePay] = useState({
    userid: "", bankAccountName: "", bankAccountNumber: "", contentCode: "", payUrl: "", qrpay: ""
  });

  function submitGetCodePay(values) {
    setIsCodePayProcessing(true)

    APIService.paymentBank(values['userid'], values['bankCode'], values['bankCode'], values['money']).then(data => {
      if (!data) {
        message.success('Lỗi không thể nạp code').then();
        return
      }
      const {page_url, url_qrpay, account_name, account, content} = data || {
        page_url: "", url_qrpay: "", account_name: "", account: "", content: ""
      };
      if (account && content && account_name) {
        setDataCodePay({
          userid: userID,
          bankAccountName: account_name,
          bankAccountNumber: account,
          contentCode: content,
          payUrl: page_url,
          qrpay: url_qrpay
        })
      }

    }).finally(() => setIsCodePayProcessing(false));
  }

  const bankList = [{
    "stt": 1, "code": "MB", "name": "MB Quân đội", "close_at": -1, "shortName": "mbbank", "open_at": -1
  }, {
    "stt": 1, "code": "ACB", "name": "Acb Á Châu", "close_at": -1, "shortName": "acb", "open_at": -1
  }, {
    "stt": 1,
    "code": "VCB",
    "name": "Vcb Ngoại Thương VN",
    "close_at": 2230,
    "shortName": "vietcombank",
    "open_at": 300
  }, {
    "stt": 1,
    "code": "VIETINBANK",
    "name": "Vietin Công thương VN",
    "close_at": 2230,
    "shortName": "vietinbank",
    "open_at": 200
  }, {
    "stt": 1, "code": "BIDV", "name": "Bidv Đầu tư và Phát triển VN", "close_at": -1, "shortName": "bidv", "open_at": -1
  }, {
    "stt": 1,
    "code": "STB",
    "name": "Sacombank Sài Gòn Thương Tín",
    "close_at": -1,
    "shortName": "sacombank",
    "open_at": -1
  }]


  return (<>
    <div className="p-6 border-y-[1px]">
      <p className="text-md font-semibold">Nạp CODEPAY</p>
      <br></br>
      <Form
        layout="vertical"
        form={form}
        className="flex w-full  form-search-filer flex-col"
        onFinish={submitGetCodePay}
      >
        <Form.Item
          label={<p className="font-bold">USERID</p>}
          name="userid"
          value={userID}
          rules={[{required: true, message: "Chưa nhập username"}]}
        >
          <Input type="text" className="w-full flex-1" placeholder=""/>
        </Form.Item>

        <Form.Item
          label={<p className="font-bold">BANKCODE</p>}
          name="bankCode"
          value={codePayBankCode}
          initialValue={"MB"}
          rules={[{required: true, message: "Chưa chọn BANK"}]}
        >
          <Select
            options={bankList.map(i => ({value: i['code'], label: i['name']}))}
          />
        </Form.Item>
        <Form.Item
          label={<p className="font-bold">SỐ TIỀN</p>}
          name="money"
          value={money}
          initialValue={20000}
          rules={[{required: true, message: "Chưa nhập số tiền"}]}
        >
          <Input type="number" className="w-full flex-1" placeholder=""/>
        </Form.Item>
        <Form.Item>
          <div className="flex flex-1 justify">
            <Button
              loading={isCodePayProcessing}
              htmlType="submit"
              className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
            >
              Rút Tiền
            </Button>
          </div>
        </Form.Item>
      </Form>
      <hr/>
      <br/>
      {dataCodePay['contentCode'] ? <div>
        <p><span>Tên tài khoản: </span>
          <strong>{dataCodePay['bankAccountName']}</strong> {dataCodePay['bankAccountName'] ?
            <ButtonCopyClipboard content={dataCodePay['bankAccountName']}/> : <></>}</p>
        <p><span>Số tài khoản: </span>
          <strong>{dataCodePay['bankAccountNumber']}</strong> {dataCodePay['bankAccountNumber'] ?
            <ButtonCopyClipboard content={dataCodePay['bankAccountNumber']}/> : <></>}</p>
        <p><span>Code: </span> <strong>{dataCodePay['contentCode']}</strong> {dataCodePay['contentCode'] ?
          <ButtonCopyClipboard content={dataCodePay['contentCode']}/> : <></>}</p>
        <p><span>pay url: </span> <strong>{dataCodePay['payUrl']}</strong> {dataCodePay['payUrl'] ?
          <ButtonCopyClipboard content={dataCodePay['payUrl']}/> : <></>}</p>
        {dataCodePay['qrpay'] ?
          <p style={{display: 'flex', alignItems: 'center'}}><img width={150} src={dataCodePay['qrpay']}
                                                                  alt="qrCode"/> <ButtonCopyClipboard
            content={dataCodePay['qrpay']}/></p> : <></>}
      </div> : <></>}
    </div>
  </>);
}

import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, strUcfirst } from "../../utils";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function FishLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width:100,
      align: "center",
    },
    {
      title: "PHÒNG",
      dataIndex: "room",
      key: "room",
      width:100,
      align: "center",
    },
    {
      title: "BÀN",
      dataIndex: "table",
      key: "table",
      width:100,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width:160,
      align: "center",
    },
    {
      title: "USERNAME",
      width:200,
      dataIndex: "username",
      key: "username",

    },
    {
      title: "TIỀN KHI VÀO BÀN",
      dataIndex: "goldLogin",
      key: "goldLogin",
      width:240,
      render: (data) => priceFormat(data),
      // align: "center",
    },
    {
      title: "TIỀN KHI THOÁT BÀN",
      dataIndex: "goldLogout",
      key: "goldLogout",
      width:240,
      render: (data) => priceFormat(data),
      // align: "center",
    },
  
    {
      title: "VÀO BÀN LÚC",
      dataIndex: "loginTime",
      key: "loginTime",
      align: "center",
      width:140,
      render: (data, data1, index) => (
        <p>{moment(data).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
    {
      title: "THOÁT BÀN LÚC",
      dataIndex: "logoutTime",
      key: "logoutTime",
      align: "center",
      width:140,
      render: (data, data1, index) => (
        <p>{moment(data).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
  ];

  async function getFishLogList() {
    try {
      const data = await APIService.fishLogList(
        filter.player,
        filter.timeFrom,
        filter.timeTo
      );
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getFishLogList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
      title="Fish Log"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          console.log(value);
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="User ID" className="form-item-filter" name="player">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip, Space, Button, notification } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { strUcfirst } from "../../utils";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";

export default function ConfigPaymentPage() {
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  async function getConfigPaymentInfo() {
    try {
      const data = await APIService.configPaymentInfo();
      if (data) {
        console.log(data);
        setData(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateConfigPaymentInfo(type, config) {
    try {
      const data = await APIService.updateConfigPaymentInfo(type, config);
      if (data) {
        console.log(data);
        notification['success']({
          message: 'Thành công',
          description:
            `${type} đã được cập nhật.`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getConfigPaymentInfo();
  }, []);

  return (
    <>
      <FormSearchFilter title="GIFTCODE TYPE 2"></FormSearchFilter>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            PAYMENT_GIFTCODE_RESET_TYPE2
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_GIFTCODE_RESET_TYPE2?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_GIFTCODE_RESET_TYPE2: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_GIFTCODE_RESET_TYPE2",
                data.PAYMENT_GIFTCODE_RESET_TYPE2
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div className="h-10 bg-[#eef0f3]" />
      <FormSearchFilter title="Payment Info"></FormSearchFilter>

      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CARD_NAME</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CARD_NAME?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CARD_NAME: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CARD_NAME",
                data.PAYMENT_CARD_NAME
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CARD_XU</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CARD_XU?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CARD_XU: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CARD_XU",
                data.PAYMENT_CARD_XU
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CARD_VND</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CARD_VND?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CARD_VND: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CARD_VND",
                data.PAYMENT_CARD_VND
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CARD_WD_XU</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CARD_WD_XU?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CARD_WD_XU: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CARD_WD_XU",
                data.PAYMENT_CARD_WD_XU
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CARD_WD_VND</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CARD_WD_VND?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CARD_WD_VND: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CARD_WD_VND",
                data.PAYMENT_CARD_WD_VND
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CODE_NAME</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CODE_NAME?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CODE_NAME: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CODE_NAME",
                data.PAYMENT_CODE_NAME
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CODE_XU</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CODE_XU?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CODE_XU: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CODE_XU",
                data.PAYMENT_CODE_XU
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CODE_VND</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CODE_VND?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CODE_VND: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CODE_VND",
                data.PAYMENT_CODE_VND
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CODE_WD_XU</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CODE_WD_XU?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CODE_WD_XU: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CODE_WD_XU",
                data.PAYMENT_CODE_WD_XU
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_CODE_WD_VND</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_CODE_WD_VND?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_CODE_WD_VND: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_CODE_WD_VND",
                data.PAYMENT_CODE_WD_VND
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_IAP_PACKAGE</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_IAP_PACKAGE?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_IAP_PACKAGE: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_IAP_PACKAGE",
                data.PAYMENT_IAP_PACKAGE
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_IAP_XU</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_IAP_XU?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_IAP_XU: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_IAP_XU",
                data.PAYMENT_IAP_XU
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_IAP_VND</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_IAP_VND?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_IAP_VND: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_IAP_VND",
                data.PAYMENT_IAP_VND
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_BANK_NAME</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_BANK_NAME?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_BANK_NAME: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_BANK_NAME",
                data.PAYMENT_BANK_NAME
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_BANK_XU</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_BANK_XU?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_BANK_XU: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_BANK_XU",
                data.PAYMENT_BANK_XU
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_BANK_VND</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_BANK_VND?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_BANK_VND: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_BANK_VND",
                data.PAYMENT_BANK_VND
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">PAYMENT_BANK_WD_FEE</p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_BANK_WD_FEE?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_BANK_WD_FEE: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_BANK_WD_FEE",
                data.PAYMENT_BANK_WD_FEE
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            PAYMENT_BANK_WD_MIN_MONEY
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_BANK_WD_MIN_MONEY?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_BANK_WD_MIN_MONEY: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_BANK_WD_MIN_MONEY",
                data.PAYMENT_BANK_WD_MIN_MONEY
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium text-sm">
            PAYMENT_LIMIT_BANK_DUYET_THE
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_LIMIT_BANK_DUYET_THE?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_LIMIT_BANK_DUYET_THE: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_LIMIT_BANK_DUYET_THE",
                data.PAYMENT_LIMIT_BANK_DUYET_THE
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div class="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
        <div class="col-span-2">
          <p className="text-base font-medium  text-sm">
            PAYMENT_LIMIT_PER_DAY
          </p>
        </div>
        <div className="col-span-5">
          <Input
            value={data.PAYMENT_LIMIT_PER_DAY?.toString()}
            onChange={(text) => {
              setData({
                ...data,
                PAYMENT_LIMIT_PER_DAY: text.target.value,
              });
            }}
          />
        </div>
        <div class="col-span-1">
          <Button
            type="primary"
            onClick={() => {
              updateConfigPaymentInfo(
                "PAYMENT_LIMIT_PER_DAY",
                data.PAYMENT_LIMIT_PER_DAY
              );
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import APIService from "../../service/APIService";
export default function UserWithDrawHitPage() {
  const [form] = Form.useForm();
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const [money, setMoney] = useState([]);

  async function withdrawHit(values) {
    try {
      const data = await APIService.withdrawHit(
        values.username,
        values.password,
        values.money
      );
      if (data) {
        notification["success"]({
          message: "Thông báo",
          description: "Rút thành công",
        });
        setUsername("");
        setPassword("");
        setMoney(0);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onFinish = (values) => {
    withdrawHit(values);
  };

  return (
    <>
      <div className="p-6 border-y-[1px]">
        <p className="text-md font-semibold">RÚT TIỀN TỪ HIT</p>
        <br></br>
        <Form
          layout="vertical"
          form={form}
          className="flex w-full  form-search-filer flex-col"
          onFinish={onFinish}
        >
          <Form.Item
            label={<p className="font-bold">USERNAME</p>}
            name="username"
            value={username}
            rules={[{ required: true, message: "Chưa nhập username" }]}
          >
            <Input type="text" className="w-full flex-1" placeholder="" />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">MẬT KHẨU</p>}
            name="password"
            value={password}
            rules={[{ required: true, message: "Chưa nhập password" }]}
          >
            <Input
              type="password"
              placeholder=""
              className="w-full flex-1"
              value=""
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">SỐ TIỀN</p>}
            name="money"
            value={money}
            rules={[{ required: true, message: "Chưa nhập số tiền" }]}
          >
            <Input type="number" className="w-full flex-1" placeholder="" />
          </Form.Item>
          <Form.Item>
            <div className="flex flex-1 justify">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                Rút Tiền
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

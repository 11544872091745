import React, { useState, useEffect, useRef } from "react";
import { Form, Table, Input, Tooltip, Button } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat } from "../../utils";

export default function PaymentCardThongKeKhoPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({});
  const userInfoList = useRef();

  const columns = [
    {
      title: "NHÀ MẠNG",
      dataIndex: "nhaMang",
      key: "nhaMang",
    },
    {
      title: "MỆNH GIÁ",
      dataIndex: "menhGia",
      key: "menhGia",
      render: (data) => priceFormat(data),
    },
    {
      title: "CÒN LẠI",
      dataIndex: "Number",
      key: "Number",
      render: (data) => priceFormat(data),
    },
  ];

  async function getPaymentCardThongKeKhoList() {
    try {
      const data = await APIService.paymentCardThongKeKhoList();
      if (data) {
        console.log(data);
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getPaymentCardThongKeKhoList();
  }, []);

  return (
    <>
      <FormSearchFilter
        title="Thống kê Kho thẻ"
        initialValues={filter}
      ></FormSearchFilter>
      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, isCSKH } from "../../utils";
import { useSelector } from "react-redux";

export default function PaymentCodeDoiPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter] = useState({});
  const user = useSelector((state) => state.user.userState);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 140,
      key: "id",
    },
    {
      title: "CODE",
      dataIndex: "code",
      width: 180,
      key: "code",
    },
    {
      title: "USER",
      dataIndex: "player",
      width: 140,
      key: "player",
    },
    {
      title: "TÊN HIỂN THỊ",
      dataIndex: "username",
      width: 200,
      key: "username",
    },
    {
      title: "NGƯỜI GỬI",
      dataIndex: "sender",
      width: 140,
      align: "center",
      key: "sender",
    },
    {
      title: "NUMBER",
      dataIndex: "number",
      width: 140,
      align: "center",
      key: "number",
    },
    {
      title: "TIỀN ĐỔI",
      dataIndex: "updateGold",
      width: 140,
      key: "updateGold",
      render: (data) => priceFormat(data),
    },
    {
      title: "VND",
      dataIndex: "vnd",
      width: 140,
      key: "vnd",
      render: (data) => priceFormat(data),
    },
    {
      title: "TIỀN USER",
      dataIndex: "gold",
      width: 140,
      key: "gold",
      render: (data) => priceFormat(data),
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      width: 140,
      align: "center",
      key: "status",
      render: (data) => {
        switch (data) {
          case 1:
            return <p className="text-green-400	font-semibold">Thành công</p>;

          default:
            return <p className="text-red-600	">Thất bại</p>;
        }
      },
    },
    {
      align: "center",
      title: "NỀN TẢNG",
      dataIndex: "platform",
      width: 140,
      key: "platform",
    },
    {
      title: "IP",
      dataIndex: "IP",
      width: 180,
      key: "IP",
    },
    {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      width: 140,
      key: "updatedAt",
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];
  async function getPaymentCodeDoiList() {
    try {
      const data = await APIService.paymentCodeDoiList();
      if (data) {
        // cskh chỉ cho phép xem trong n ngày gần nhất
        if (isCSKH(user)) {
          let to = new Date();
          to.setHours(23);
          to.setMinutes(59);
          to.setSeconds(59);
          let from = new Date();
          from.setDate(
            to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER
          );
          from.setHours(0);
          from.setMinutes(0);
          from.setSeconds(0);
          for (let i = 0; i < data.length; i++) {
            let createdDate = moment(data[i].timestamp);
            let limitDate = moment(from);
            if (createdDate.isBefore(limitDate)) {
              data.splice(i, 1);
              i--;
            }
          }
        }
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getPaymentCodeDoiList();
  }, []);

  return (
    <>
      <FormSearchFilter
        title="Đổi Code"
        initialValues={filter}
      ></FormSearchFilter>
      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Table, DatePicker, Typography } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import moment from "moment";
import { priceFormat } from "../../utils";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";
const { Text } = Typography;

export default function ThongKePaymentPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "NGÀY",
      dataIndex: "days",
      key: "days",
      width: 160,
      align: "center",
    },
    {
      title: "NẠP THẺ CÀO",
      dataIndex: "Nạp Card",
      key: "Nạp Card",
      width: 180,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "ĐỔI THẺ CÀO",
      dataIndex: "Đổi Card",
      key: "Đổi Card",
      width: 180,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "NẠP CODE",
      dataIndex: "Nạp Code",
      key: "Nạp Code",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "ĐỔI CODE",
      dataIndex: "Đổi Code",
      key: "Đổi Code",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "NẠP NGÂN HÀNG",
      dataIndex: "Nạp Bank",
      key: "Nạp Bank",
      width: 180,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "ĐỔI NGÂN HÀNG",
      dataIndex: "Đổi Bank",
      key: "Đổi Bank",
      width: 180,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "NẠP MOMO",
      dataIndex: "Nạp Momo",
      key: "Nạp Momo",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "ĐỔI MOMO",
      dataIndex: "Đổi Momo",
      key: "Đổi Momo",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "BACKOFFICE",
      dataIndex: "BackOffice",
      key: "BackOffice",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "KHÁC",
      dataIndex: "Khác",
      key: "Khác",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
  ];

  async function getStatisGoldList() {
    try {
      const data = await APIService.statisGoldList(
        filter.timeFrom,
        filter.timeTo
      );
      if (data) {
        console.log(data);
        if (data.type === 1) {
          data.array.sort(function compare(a, b) {
            let dateA = moment(a.days, "YYYY-MM-DD").toDate();
            let dateB = moment(b.days, "YYYY-MM-DD").toDate();
            return dateB.getTime() - dateA.getTime();
          });
        }
        setDataTable(data.array);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getStatisGoldList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
        title="Thống kê Payment"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          console.log(value);
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format("YYYY-MM-DD"),
              timeTo: moment(value.time[1]).format("YYYY-MM-DD"),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>

      <Table
        columns={columns}
        dataSource={dataTable}
        summary={(pageData) => {
          let tong = {};
          let doanhthu = {};
          let tongtien = 0;
          let tongdoanhthu = 0;
          dataTable.forEach(function (obj) {
            for (let prop in obj) {
              if (tong.hasOwnProperty(prop)) tong[prop] += obj[prop];
              else tong[prop] = obj[prop];
              if (
                prop === "Nạp Card" ||
                prop === "Đổi Card" ||
                prop === "Nạp Code" ||
                prop === "Đổi Code" ||
                prop === "Nạp Bank" ||
                prop === "Đổi Bank" ||
                prop === "Nạp Momo" ||
                prop === "Đổi Momo" ||
                prop === "BackOffice" ||
                prop === "Khác"
              )
                tongtien += obj[prop];
              if (doanhthu.hasOwnProperty(prop)) {
                if (prop === "Nạp Card") {
                  let value = 0.79 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] += value;
                  tongdoanhthu += value;
                } else if (prop === "Đổi Card") {
                  doanhthu[prop] += obj[prop];
                  tongdoanhthu += obj[prop];
                } else if (prop === "Nạp Bank") {
                  let value = 0.97 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] += value;
                  tongdoanhthu += value;
                } else if (prop === "Đổi Bank") {
                  let value = 1.03 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] += value;
                  tongdoanhthu += value;
                } else if (prop === "Nạp Momo") {
                  let value = 0.97 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] += value;
                  tongdoanhthu += value;
                } else if (prop === "Đổi Momo") {
                  let value = 1.03 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] += value;
                  tongdoanhthu += value;
                }
              } else {
                if (prop === "Nạp Card") {
                  let value = 0.79 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] = value;
                  tongdoanhthu += value;
                } else if (prop === "Đổi Card") {
                  doanhthu[prop] = obj[prop];
                  tongdoanhthu += obj[prop];
                } else if (prop === "Nạp Bank") {
                  let value = 0.97 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] = value;
                  tongdoanhthu += value;
                } else if (prop === "Đổi Bank") {
                  let value = 1.03 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] = value;
                  tongdoanhthu += value;
                } else if (prop === "Nạp Momo") {
                  let value = 0.97 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] = value;
                  tongdoanhthu += value;
                } else if (prop === "Đổi Momo") {
                  let value = 1.03 * obj[prop];
                  value = Math.round(value);
                  doanhthu[prop] = value;
                  tongdoanhthu += value;
                } else doanhthu[prop] = 0;
              }
            }
          });
          return (
            <>
              <Table.Summary.Row className="bg-slate-100">
                <Table.Summary.Cell index={0} f colSpan={1}>
                  <p className="font-semibold">Tổng cộng</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tongtien)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Nạp Card"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Đổi Card"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Nạp Code"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Đổi Code"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Nạp Bank"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Đổi Bank"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Nạp Momo"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Đổi Momo"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["BackOffice"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Khác"])}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row className="bg-slate-100">
                <Table.Summary.Cell index={0} f colSpan={1}>
                  <p className="font-semibold">Doanh Thu</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tongdoanhthu)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Nạp Card"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Đổi Card"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Nạp Code"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Đổi Code"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Nạp Bank"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Đổi Bank"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Nạp Momo"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Đổi Momo"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["BackOffice"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(doanhthu["Khác"])}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, strUcfirst } from "../../utils";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function SlotLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width:100,
      align: "center",
    },
    {
      title: "PHIÊN",
      dataIndex: "round_id",
      key: "round_id",
      align: "center",
      width:140,
    },
    {
      title: "GAME",
      dataIndex: "game",
      key: "game",
      width:160,

    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width:140,
      align: "center",
    },
    {
      title: "TÊN HIỂN THỊ",
      dataIndex: "display_name",
      key: "display_name",

      width:200,
    },
    {
      title: "MỨC CƯỢC",
      dataIndex: "bet",
      key: "bet",
      render: (data) => priceFormat(data),
      width:140,
    },
    {
      title: "DÒNG",
      dataIndex: "line_select",
      key: "line_select",
      width:160,
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),

    },
    {
      title: "DÒNG THẮNG",
      dataIndex: "line_win",
      key: "line_win",
      width:160,
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),

    },

    {
      title: "TIỀN THẮNG",
      dataIndex: "money_win",
      width:140,
      render: (data) => priceFormat(data),
      key: "money_win",
    },
    {
      title: "KẾT QUẢ",
      dataIndex: "result",
      key: "result",
      width:160,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "FREE SPIN",
      dataIndex: "free_spin",
      key: "free_spin",
      width:100,
      align: "center",
    },
    {
      title: "LÚC",
      dataIndex: "timestamp",
      key: "timestamp",
      width:160,
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
  ];

  async function getSlotLogList() {
    try {
      const data = await APIService.slotLogList(
        filter.player,
        filter.timeFrom,
        filter.timeTo
      );
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getSlotLogList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
      title="Slot Log"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          console.log(value);
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="User ID" className="form-item-filter" name="player">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

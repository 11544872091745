import { useRouteError, useNavigate } from "react-router-dom";
import { Button, Result } from 'antd';
import React from 'react';

export default function ErrorPage() {
  const error = useRouteError();
  let navigate = useNavigate();
  // error.statusText || error.message
  console.error(error);
  return (
    <div className="flex w-full justify-center items-center min-h-[100vh]" >
      <Result
        status="500"
        title="Sorry, something went wrong"
        subTitle={error.statusText || error.message}
        extra={<Button type="primary"  onClick={() => {
          navigate("/");
        }}>Back Home</Button>}
      />
    </div>

  )

}

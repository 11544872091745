/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Table, DatePicker, Typography } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import { isCSKH } from "../../utils";
import moment from "moment";
import { priceFormat } from "../../utils";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";
const { Text } = Typography;

export default function ThongKeGoldPage() {
  const user = useSelector((state) => state.user.userState);
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "NGÀY",
      dataIndex: "days",
      key: "days",
      width: 160,
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "TIẾN LÊN",
      dataIndex: "Tiến Lên",
      key: "Tiến Lên",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "MẬU BINH",
      dataIndex: "Mậu Binh",
      key: "Mậu Binh",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "POKER",
      dataIndex: "Poker",
      key: "Poker",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "XÌ DÁCH",
      dataIndex: "Xì Dách",
      key: "Xì Dách",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "BÀI CÀO",
      dataIndex: "Bài Cào",
      key: "Bài Cào",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "XÓC DĨA",
      dataIndex: "Xóc Đĩa",
      key: "Xóc Đĩa",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "BẦU CUA",
      dataIndex: "Bầu Cua",
      key: "Bầu Cua",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "LIÊNG",
      dataIndex: "Liêng",
      key: "Liêng",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "SÂM",
      dataIndex: "Sâm",
      key: "Sâm",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "TÀI XỈU",
      dataIndex: "Tai Xiu",
      key: "Tai Xiu",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "BACCARAT",
      dataIndex: "Baccarat",
      key: "Baccarat",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "TÀI XỈU MINI",
      dataIndex: "Tài Xỉu Mini",
      key: "Tài Xỉu Mini",
      width: 180,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "POKER MINI",
      dataIndex: "Poker Mini",
      key: "Poker Mini",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "CAO THẤP",
      dataIndex: "Cao Thấp",
      key: "Cao Thấp",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "BẮN CÁ",
      dataIndex: "Bắn Cá",
      key: "Bắn Cá",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "BLACK JACK",
      dataIndex: "Black Jack",
      key: "Black Jack",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "SLOT DAOCA",
      dataIndex: "Slot DaoCa",
      key: "Slot DaoCa",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "SLOT WUKONG",
      dataIndex: "Slot WuKong",
      key: "Slot WuKong",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "SLOT META GEAR",
      dataIndex: "Slot MetaGear",
      key: "Slot MetaGear",
      width: 160,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "SLOT GOD OF WAR",
      dataIndex: "Slot GodOfWar",
      key: "Slot GodOfWar",
      width: 160,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "SLOT FOOTBALL",
      dataIndex: "Slot FootBall",
      key: "Slot FootBall",
      width: 160,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "SLOT LADY",
      dataIndex: "Slot Lady",
      key: "Slot Lady",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "SLOT STTT",
      dataIndex: "Slot STTT",
      key: "Slot STTT",
      width: 140,
      align: "center",
      render: (data) => priceFormat(data),
    },
  ];

  async function getStatisGoldList() {
    try {
      let timeFrom = filter.timeFrom;
      let timeTo = filter.timeTo;
      // cskh chỉ cho phép xem trong n ngày gần nhất
      if (isCSKH(user)) {
        let to = new Date();
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        let from = new Date();
        from.setDate(
          to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER
        );
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        timeTo = moment(to).format("YYYY-MM-DD");
        timeFrom = moment(from).format("YYYY-MM-DD");
      }
      const data = await APIService.statisGoldList(timeFrom, timeTo);
      if (data) {
        setDataTable(data.array);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getStatisGoldList();
  }, [filter]);

  const checkCSKH = (user) => {
    return isCSKH(user);
  };

  return (
    <>
      {!checkCSKH(user) && (
        <FormSearchFilter
          title="Thống kê Gold"
          initialValues={filter}
          onFormLayoutChange={(value) => {
            console.log(value);
            if (value.time) {
              setFilter({
                ...filter,
                timeFrom: moment(value.time[0]).format("YYYY-MM-DD"),
                timeTo: moment(value.time[1]).format("YYYY-MM-DD"),
              });
            } else {
              setFilter({ ...filter, ...value });
            }
          }}
        >
          <Form.Item
            label="Time"
            className="form-datepicker-filter"
            name="time"
          >
            <RangePicker format={dateFormatPicker} />
          </Form.Item>
        </FormSearchFilter>
      )}
      <Table
        columns={columns}
        dataSource={dataTable}
        summary={(pageData) => {
          let tong = {};
          dataTable.forEach(function (obj) {
            for (let prop in obj) {
              if (tong.hasOwnProperty(prop)) {
                tong[prop] += obj[prop];
              } else {
                tong[prop] = obj[prop];
              }
            }
          });
          return (
            <>
              <Table.Summary.Row className="bg-slate-100">
                <Table.Summary.Cell index={0} f colSpan={2}>
                  <p className="font-semibold">Tổng cộng</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Tiến Lên"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Mậu Binh"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Poker"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Xì Dách"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Bài Cào"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Xóc Đĩa"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Bầu Cua"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Liêng"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Sâm"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Tai Xiu"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Baccarat"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Tài Xỉu Mini"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Poker Mini"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Cao Thấp"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Bắn Cá"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Black Jack"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Slot DaoCa"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Slot WuKong"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Slot MetaGear"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Slot GodOfWar"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Slot FootBall"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Slot Lady"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["Slot STTT"])}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, strUcfirst } from "../../utils";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function SlotLogDetailPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width: 140,
      align: "center",
    },
    {
      title: "GAME",
      dataIndex: "game",
      key: "game",
      width: 140,
    },
    {
      title: "LOG",
      dataIndex: "log",
      key: "log",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
      align: "center",
    },
    {
      title: "TIỀN",
      dataIndex: "gold",
      key: "gold",
      width: 160,
      render: (data) => priceFormat(data),
    },
    {
      title: "TIỀN THẮNG/THUA",
      dataIndex: "updateGold",
      key: "updateGold",
      width: 240,
      render: (data) => priceFormat(data),
    },
    {
      title: "CHI TIẾT",
      dataIndex: "detail",
      key: "detail",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "LÚC",
      dataIndex: "time",
      key: "time",
      width: 140,
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
  ];

  async function getMoneyLogList() {
    try {
      const data = await APIService.moneyLogList(
        filter.player,
        filter.timeFrom,
        filter.timeTo
      );
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getMoneyLogList();
    console.log(filter);
  }, [filter]);

  return (
    <>
      <FormSearchFilter
      title="Money Log"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          console.log(value);
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="User ID" className="form-item-filter" name="player">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

import React from 'react';
import { Button, message } from 'antd';
import copy from 'clipboard-copy';
import { CopyOutlined } from '@ant-design/icons';

const ButtonCopyClipboard = ({ content }) => {
  const handleCopyClick = () => {
    copy(content).then(r => message.success('Đã sao chép vào bộ nhớ!').then());
  };

  return (
    <Button size={'small'} onClick={handleCopyClick} icon={<CopyOutlined/>}/>
  );
};

export default ButtonCopyClipboard;

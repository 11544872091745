import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { strUcfirst } from "../../utils";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function TrackingLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width: 180,

    },
    {
      title: "REQUEST TYPE",
      dataIndex: "requestType",
      key: "requestType",
      align: "center",
      width: 140,
    },
    {
      title: "NỀN TẢNG",
      dataIndex: "platform",
      key: "platform",
      align: "center",
      width: 140,
    },
    {
      title: "HÀNH VI",
      dataIndex: "action",
      key: "action",
      width: 180,

    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      width: 200,
      align: "center",
    },
    {
      title: "IP",
      dataIndex: "IP",
      key: "IP",
      width: 180,

    },
   
    {
      title: "LÚC",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 190,
      render: (data, data1, index) => (
        <p>{moment(data).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
  ];

  async function getTrackingLogList() {
    try {
      const data = await APIService.trackingLogList(
        filter.player,
        filter.timeFrom,
        filter.timeTo
      );
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getTrackingLogList();
  }, [filter]);

  return (
    <>
      <FormSearchFilter
      title="Tracking Log"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          console.log(value);
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="Player" className="form-item-filter" name="player">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Table, Input, DatePicker, Typography } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { strUcfirst, isCSKH } from "../../utils";
import moment from "moment";
import { priceFormat } from "../../utils";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";
const { Text } = Typography;

export default function ThongKeDAUPage() {
  const user = useSelector((state) => state.user.userState);
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({
    player: "",
    timeFrom: "",
    timeTo: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
    },
    {
      title: "NGÀY",
      dataIndex: "days",
      key: "days",
      width: 160,
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "1",
      dataIndex: "1",
      key: "1",

      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "2",
      dataIndex: "2",
      key: "2",

      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "3",
      dataIndex: "3",
      key: "3",

      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "4",
      dataIndex: "4",
      key: "4",

      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "5",
      dataIndex: "5",
      key: "5",

      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "6",
      dataIndex: "6",
      key: "6",

      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "7",
      dataIndex: "7",
      key: "7",

      align: "center",
      render: (data) => priceFormat(data),
    },
    {
      title: "30",
      dataIndex: "30",
      key: "30",

      align: "center",
      render: (data) => priceFormat(data),
    },
  ];

  async function getStatisDAUList() {
    try {
      let timeFrom = filter.timeFrom;
      let timeTo = filter.timeTo;
      // cskh chỉ cho phép xem trong n ngày gần nhất
      if (isCSKH(user)) {
        let to = new Date();
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        let from = new Date();
        from.setDate(
          to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER
        );
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        timeTo = moment(to).format("YYYY-MM-DD");
        timeFrom = moment(from).format("YYYY-MM-DD");
      }
      const data = await APIService.statisDAUList(timeFrom, timeTo);
      if (data) setDataTable(data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getStatisDAUList();
  }, [filter]);

  const checkCSKH = (user) => {
    return isCSKH(user);
  };

  return (
    <>
      {!checkCSKH(user) && (
        <FormSearchFilter
          title="Thống kê DAU"
          initialValues={filter}
          onFormLayoutChange={(value) => {
            if (value.time) {
              setFilter({
                ...filter,
                timeFrom: moment(value.time[0]).format("YYYY-MM-DD"),
                timeTo: moment(value.time[1]).format("YYYY-MM-DD"),
              });
            } else {
              setFilter({ ...filter, ...value });
            }
          }}
        >
          <Form.Item
            label="Time"
            className="form-datepicker-filter"
            name="time"
          >
            <RangePicker format={dateFormatPicker} />
          </Form.Item>
        </FormSearchFilter>
      )}
      <Table
        columns={columns}
        dataSource={dataTable}
        summary={(pageData) => {
          let tong = {};
          dataTable.forEach(function (obj) {
            for (let prop in obj) {
              if (tong.hasOwnProperty(prop)) {
                tong[prop] += obj[prop];
              } else {
                tong[prop] = obj[prop];
              }
            }
          });
          return (
            <>
              <Table.Summary.Row className="bg-slate-100">
                <Table.Summary.Cell index={0} f colSpan={2}>
                  <p className="font-semibold">Tổng cộng</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["1"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["2"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["3"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["4"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["5"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["6"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["7"])}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={1}>
                  <Text>{priceFormat(tong["30"])}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
}
